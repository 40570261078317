import { mapState } from "vuex";
import rules from "@/utils/validation";
import InfoIcon from "@/components/plugins/formGenerator/InfoIcon";

export const formActionsMixin = {
	/**
	 * Each questionnaire has two keys-
	 * 1. answer
	 * 2. formatted_answer
	 * We are using "formatted_answer" for display because this is in proper format.
	 * But, at the time of submit we will post values on "answer" key.
	 */
	data() {
		return {
			rules,
			question_styles: {
				"greyDarken4--text": true,
				"subtitle-1": true,
			},
		};
	},

	props: {
		questionnaire_id: {
			required: true,
		},
	},

	components: {
		InfoIcon,
	},

	computed: {
		...mapState({
			current_questionnaire: (state) =>
				state.questionnaire.list.questionnaire.name,
		}),

		questionnaire() {
			return this.questionnaire_id
				? this.$store.getters["questionnaire/getById"](this.questionnaire_id)
				: null;
		},

		question() {
			return `
                ${this.questionnaire.question.order}
                .\xa0
                ${this.questionnaire.question.name}
            `;
		},
	},

	methods: {
		onSpeechEnd(text, form_ref) {
			try {
				// Save emitted value by microphone to answer
				this.questionnaire.formated_answer = text;

				/**
				 * When click on mic icon, the focus will be detached from the input, so,
				 * after off the mic, enable the focus to input element again because
				 * answer will be saved only on blur event and to trigger blur event
				 * we need to focus first.
				 */
				this.$nextTick(() => {
					// There are two index in $el array, 0th is label and 1st is input.
					this.$refs[form_ref].$el[1].focus();
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async pushKeywordToAnswer(keyword, form_ref) {
			try {
				this.$loader.start();

				this.questionnaire.formated_answer += !this.questionnaire
					.formated_answer
					? keyword
					: `, ${keyword}`;

				this.submitAnswerIfModified(form_ref, false);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		submitAnswerIfModified(form_ref, check_validation = true) {
			try {
				// when passing keywords from suggestions, no need to check validation
				if (check_validation) {
					if (!this.$refs[form_ref]) return;

					// If form is not valid then return from here
					if (!this.$refs[form_ref].validate()) {
						return;
					}
				}

				// Send API request only if answers has changed.
				if (this.questionnaire.answer !== this.questionnaire.formated_answer) {
					// This event will listen by parent (FormGenerator.vue).
					this.$emit("submit-answer", {
						questionnaire_id: this.questionnaire.id,
						answer: this.questionnaire.formated_answer,
					});
				}
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
