<template>
	<v-card-text class="pt-0">
		<v-chip-group>
			<v-chip
				color="greyLighten2"
				v-for="keyword in related_keywords"
				:key="keyword.id"
			>
				{{ keyword.name }}
			</v-chip>
		</v-chip-group>
	</v-card-text>
</template>

<script>
export default {
	name: "SourcesAssignmentKeywords",

	props: {
		related_keywords: {
			required: true,
		},
	},
};
</script>

<style></style>
