import firebase from "@/services/firebase";
import { findElIndex, isArrayHavingItem, findFromArray } from "@/utils/helpers";
import { mapGetters } from "vuex";

const dbRef = firebase.database().ref("boards");

export const toolsWizardMapCollaborationMixin = {
	props: {
		input: {
			required: true,
		},
	},

	computed: {
		isOwner() {
			return this.$store.getters["wizardMindmap/isOwner"](this.input.user);
		},

		isProcessMap() {
			return this.input.category === this.$defines.SYSTEM_CATEGORY;
		},
	},

	mounted() {
		this.init();
	},

	methods: {
		handleFunctionCall(method_name) {
			this[method_name]();
		},

		async init() {
			try {
				this.$loader.start();

				// Process maps are view only so don't setup firebase listener.
				if (!this.input || this.isProcessMap) return;

				// Write the input to firebase when init on mounted
				await this.writeToFirebase();

				/**---------------------------------------
			 	* Listener On Parent
			 		----------------------------------------*/

				dbRef.child(this.input.uuid).on("value", async (snapshot) => {
					let fb_response = snapshot.val();

					// Non owner will use the fb_response to access the board
					if (!this.isOwner) {
						// If owner deleted the board
						if (!fb_response) {
							this.$announce.error("board_deleted");
							this.redirectToListPage();
						}

						// If owner disabled the board sharing
						if (!fb_response.is_sharing_active) {
							this.$announce.error("disabled");
							this.redirectToListPage();
						}
					}
				});

				/**---------------------------------------
			 * Listener On Child Addition Operation
			 ----------------------------------------*/
				dbRef
					.child(`${this.input.uuid}/data`)
					.on("child_added", async (data) => {
						let result = findFromArray(this.input.data, "id", data.val().id);

						// Push only if not exist already
						if (!result) {
							this.input.data.push(data.val());
						}
					});

				/**---------------------------------------
			 * Listener On Child Removal Operation
			 ----------------------------------------*/
				dbRef
					.child(`${this.input.uuid}/data`)
					.on("child_removed", async (data) => {
						let el_index = findElIndex(this.input.data, "id", data.val().id);
						if (el_index == -1) return;

						this.input.data.splice(el_index, 1);
					});

				/**---------------------------------------
			 * Listener On Child Updating Operation
			 ----------------------------------------*/
				dbRef
					.child(`${this.input.uuid}/data`)
					.on("child_changed", async (data) => {
						let el_index = findElIndex(this.input.data, "id", data.val().id);
						if (el_index == -1) return;

						this.$set(this.input.data, el_index, data.val());

						// Save modifications to database
						this.updateAtDatabase();
					});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async updateAtFirebase(flag, payload) {
			switch (flag) {
				case "sharing_status":
					await dbRef.child(this.input.uuid).update({
						is_sharing_active: payload.status,
					});
					// Save modifications to database
					await this.updateAtDatabase();
					break;
				case "positions":
					await dbRef.child(`${this.input.uuid}/data/${payload.id}`).update({
						top: payload.top,
						left: payload.left,
						width: payload.width,
						height: payload.height,
					});
					break;
				case "add_node":
					await dbRef
						.child(`${this.input.uuid}/data/${payload.id}`)
						.set(payload.data);
					break;
				case "remove_node":
					await dbRef.child(`${this.input.uuid}/data/${payload.id}`).remove();
					break;
				default:
					await dbRef.child(`${this.input.uuid}/data/${payload.id}`).update({
						[flag]: payload[flag],
					});
					break;
			}
		},

		async writeToFirebase() {
			this.input.fb_updated_at = new Date().toISOString();

			// first, omit the data key because this needs to be save in a diff way.
			const { data: omitted, ...rest_data } = this.input;

			// second, save the rest data.
			dbRef.child(this.input.uuid).set(rest_data);

			// third, save each item of data array on itself's id
			this.input.data.forEach((item) => {
				dbRef.child(`${this.input.uuid}/data/${item.id}`).set(item);
			});
		},

		manageTyping: function(id) {
			try {
				let self = this;
				return function(text) {
					self.updateAtFirebase("text", {
						id,
						text,
					});
				};
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async eHandler(id, event) {
			try {
				// Update only if end event happens
				if (isArrayHavingItem(["resize:end", "drag:end"], event.eventName)) {
					await this.updateAtFirebase("positions", {
						id: id,
						top: event.top,
						left: event.left,
						width: event.width,
						height: event.height,
					});
				}
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async updateAtDatabase() {
			try {
				await this.$store.dispatch("wizardMindmap/update", this.input);
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async redirectToListPage() {
			this.$router.push({
				name: "WizardMindmapsList",
			});
		},
	},

	beforeDestroy() {
		// Turn off the firebase listener
		dbRef.off();
	},
};

export const toolsWizardMapDeletionMixin = {
	computed: {
		...mapGetters({
			isMentor: "user/isMentor",
		}),
	},

	methods: {
		async executeDeletion(input_id, push = false) {
			try {
				this.$loader.start();

				await this.$store.dispatch("wizardMindmap/delete", {
					uuid: input_id,
				});

				/**
				 * Why to empty input.data?
				 * We have setup all listeners on input's data so if we remove input itself
				 * that means all data item will also be removed and data listeners will be
				 * active, but we don't want to listen data(child) if parent is about to remove, so
				 * empty the data(child) so data listeners can not do anything.
				 */
				if (this.input) {
					this.input.data = [];
				}

				// Now, delete the board from firebase too.
				await this.deleteFromFirebase(input_id);

				if (push) {
					this.$router.push({
						name: this.isMentor
							? "MentorWizardMindmapsList"
							: "WizardMindmapsList",
					});
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async deleteFromFirebase(input_id) {
			await dbRef.child(input_id).remove();
		},
	},
};
