<template>
	<div class="mt-5">
		<v-card flat class="wr_grey_1" v-if="show_heading">
			<v-card-text class="headline oflm-wr font-weight-bold">
				{{
					$t("app.how_you_performed_params", {
						module: moduleNumber,
					})
				}}
			</v-card-text>
		</v-card>
		<v-card flat class="wr_grey_1 elevation-4 ma-3 comparison-table-wr">
			<v-data-table
				disable-sort
				:headers="headers"
				:items="events"
				hide-default-footer
				class="wr_grey_1"
			>
				<!-- stage name -->
				<template v-slot:item.stage="{ item }">
					<div class="font-weight-bold">{{ item.stage }}</div>
				</template>
				<!-- plan start date -->
				<template v-slot:item.plan_start_date="{ item }">
					{{ item.plan_start_date }}
				</template>
				<!-- plan end date -->
				<template v-slot:item.plan_end_date="{ item }">
					{{ item.plan_end_date }}
				</template>
				<!-- actual start date -->
				<template v-slot:item.actual_start_date="{ item }">
					{{ item.actual_start_date }}
				</template>
				<!-- actual end date -->
				<template v-slot:item.actual_end_date="{ item }">
					{{ item.actual_end_date }}
				</template>
				<!-- starting status (by compare plan and actual start date) -->
				<template v-slot:item.starting_status="{ item }">
					<v-chip
						v-if="item.plan_start_date && item.actual_start_date"
						:color="getStyle(item.plan_start_date, item.actual_start_date)"
						class="font-weight-bold"
					>
						{{
							$t(
								`app.${getTimeStatus(
									item.plan_start_date,
									item.actual_start_date,
								)}`,
							)
						}}
						<v-icon right small>{{
							$vuetify.icons.values[
								getIcon(item.plan_start_date, item.actual_start_date)
							]
						}}</v-icon>
					</v-chip>
				</template>
				<!-- ending status (by compare plan and actual end date) -->
				<template v-slot:item.ending_status="{ item }">
					<v-chip
						v-if="item.plan_end_date && item.actual_end_date"
						:color="getStyle(item.plan_end_date, item.actual_end_date)"
						class="font-weight-bold"
					>
						{{
							$t(
								`app.${getTimeStatus(
									item.plan_end_date,
									item.actual_end_date,
								)}`,
							)
						}}
						<v-icon :right="!$vuetify.rtl" :left="$vuetify.rtl" small>{{
							$vuetify.icons.values[
								getIcon(item.plan_end_date, item.actual_end_date)
							]
						}}</v-icon>
					</v-chip>
				</template>
			</v-data-table>
		</v-card>
	</div>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters } from "vuex";

export default {
	name: "PlanningComparison",

	props: {
		prop_module: {
			required: false,
		},
		show_heading: {
			default: true,
		},
	},

	data() {
		return {
			moment,
			headers: [
				{
					text: this.$t("app.stages_of_module"),
					align: "start",
					value: "stage",
				},
				{
					text: this.$t("app.plan_start_date"),
					value: "plan_start_date",
				},
				{
					text: this.$t("app.actual_start_date"),
					value: "actual_start_date",
				},
				{
					text: this.$t("app.you_started"),
					value: "starting_status",
				},
				{
					text: this.$t("app.plan_end_date"),
					value: "plan_end_date",
				},
				{
					text: this.$t("app.actual_end_date"),
					value: "actual_end_date",
				},
				{
					text: this.$t("app.you_ended"),
					value: "ending_status",
				},
			],
		};
	},

	computed: {
		...mapState({
			current_stage: (state) => state.research.current_stage,
		}),

		...mapGetters({
			getModuleStagePlan: "planAndManage/getModuleStagePlan",
		}),

		moduleNumber() {
			return Number(this.current_stage.toString()[0]);
		},

		events() {
			var steps = {};
			let apt_module = this.prop_module ? this.prop_module : this.moduleNumber;
			return this.getModuleStagePlan(`M${apt_module}`);
		},
	},

	methods: {
		getTimeStatus(plan_date, actual_date) {
			let planned = moment(plan_date).format("YYYY-MM-DD");
			let actual = moment(actual_date).format("YYYY-MM-DD");
			if (actual > planned) {
				return this.$defines.DELAYED_STATUS_STRING;
			}
			if (actual < planned) {
				return this.$defines.EARLIER_STATUS_STRING;
			}
			return this.$defines.TIMELY_STATUS_STRING;
		},

		getStyle(plan_date, actual_date) {
			let result = this.getTimeStatus(plan_date, actual_date);
			if (result === this.$defines.EARLIER_STATUS_STRING) {
				return "info";
			}
			if (result === this.$defines.DELAYED_STATUS_STRING) {
				return "error";
			}
			return "success";
		},

		getIcon(plan_date, actual_date) {
			let result = this.getTimeStatus(plan_date, actual_date);
			if (result === this.$defines.EARLIER_STATUS_STRING) {
				return "trophy";
			}
			if (result === this.$defines.DELAYED_STATUS_STRING) {
				return "sad";
			}
			return "flag";
		},
	},
};
</script>

<style scoped>
.comparison-table-wr >>> th {
	background: var(--v-info-base);
	color: white !important;
	font-size: 1rem !important; /** 16px */
}
.comparison-table-wr >>> td {
	font-size: 0.9rem !important;
	color: rgb(0, 0, 0, 0.9) !important;
}
.comparison-table-wr >>> th:nth-child(4),
>>> th:nth-child(7) {
	text-align: center !important;
}
.comparison-table-wr >>> td:nth-child(4),
>>> td:nth-child(7) {
	text-align: center !important;
	background: var(--v-greyLighten4-base);
}
</style>
