<template>
	<v-expansion-panels flat>
		<v-expansion-panel>
			<v-expansion-panel-header class="pa-4">
				<template v-slot:actions>
					<v-icon
						:style="{ fontSize: '24px !important', color: 'blue !important' }"
						class="expansion-left-icon-wr"
						>$vuetify.icons.values.arrow_down</v-icon
					>
				</template>
				<div class="expansion-left-icon-header-wr mx-2">
					<span class="font-weight-bold subtitle-1 ">{{
						$t("app.documentation")
					}}</span>

					<template
						v-if="source.author_name || source.creator || source.candidate"
					>
						<br />
						<span>{{ displayDetails }}</span>
					</template>
				</div>
			</v-expansion-panel-header>

			<v-expansion-panel-content>
				<v-card outlined class="mt-4 greyLighten4">
					<v-card-text>
						<v-row no-gutters>
							<!-- Source Type Selection List -->
							<v-col class="px-1">
								<v-text-field
									:value="source.source_type"
									outlined
									dense
									color="greenAccent2"
									background-color="white"
									readonly
									hide-details
									:label="$t('app.source_type')"
								></v-text-field>
							</v-col>
							<!-- If source type is selected then show accessed_by -->
							<v-col class="px-1">
								<v-text-field
									v-if="source.source_type"
									:value="source.accessed_by"
									outlined
									dense
									color="greenAccent2"
									background-color="white"
									readonly
									hide-details
									:label="$t('app.access_mode')"
								></v-text-field>
							</v-col>
						</v-row>

						<!-- If source accessed_by is selected then show form to fill -->
						<v-row no-gutters v-if="source.accessed_by" class="mt-5">
							<v-col
								sm="6"
								class="px-1"
								v-for="option in options"
								:key="option.name"
							>
								<v-form>
									<!-- For "text" type fields -->
									<v-text-field
										:value="source[option.model]"
										:label="$t(option.name)"
										outlined
										dense
										color="greenAccent2"
										background-color="white"
										readonly
									></v-text-field>
								</v-form>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
import { CITATION_SOURCES } from "@/utils/helpers/source.js";

export default {
	name: "SourcesAssignmentDocumentation",

	props: {
		source: {
			required: true,
		},
	},

	computed: {
		displayDetails() {
			let person =
				this.source.author_name || this.source.creator || this.source.candidate;
			return (
				person +
				" " +
				this.source.publication_date +
				", " +
				this.source.review_date
			);
		},

		options() {
			return CITATION_SOURCES[this.source.source_type][this.source.accessed_by];
		},
	},
};
</script>

<style></style>
