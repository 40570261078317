<template>
	<v-col cols="12" v-if="questionnaire">
		<!-- Question -->
		<div :class="question_styles">
			{{ question }}
		</div>
		<!-- Answer -->
		<div :class="answer_styles">
			<span v-for="(item, index) in answer" :key="index">
				<span v-if="getNameOfAnswer(item)">
					{{ getNameOfAnswer(item) }}
					<!-- Apply comma conditionally after each answer -->
					<span v-if="index < answer.length - 1">&#44;&ensp;</span>
				</span>
			</span>
		</div>
	</v-col>
</template>

<script>
import { formAnswersMixin } from "@/mixins/questionnaire/formAnswersMixin.js";

export default {
	name: "CheckBoxAnswer",

	mixins: [formAnswersMixin],
};
</script>
