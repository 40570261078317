<template>
	<v-card height="900px" outlined>
		<QuestionBoard v-if="board_id" :prop_board_id="board_id"></QuestionBoard>
	</v-card>
</template>

<script>
import QuestionBoard from "@/components/shared/tools/questionBoard/Index.vue";

export default {
	name: "QuestionBoardAssignment",

	props: {
		student_id: {
			required: true,
		},
	},

	data() {
		return {
			board_id: null,
		};
	},

	components: {
		QuestionBoard,
	},

	mounted() {
		this.getBoard();
	},

	methods: {
		async getBoard() {
			try {
				this.$loader.start();

				let response = await this.$store.dispatch("mentorBoard/getOfStudent", {
					category: this.$defines.SYSTEM_CATEGORY,
					type: this.$defines.WHITEBOARD_TYPES.RESEARCH_QUESTION,
					student_id: this.student_id,
				});

				if (!response || !response.length) {
					throw new Error("app.ntfy.err.no_process_board", {
						cause: "werCustom",
					});
				}

				this.board_id = response[0].uuid;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
