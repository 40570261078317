var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5"},[(_vm.show_heading)?_c('v-card',{staticClass:"wr_grey_1",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"headline oflm-wr font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("app.how_you_performed_params", { module: _vm.moduleNumber, }))+" ")])],1):_vm._e(),_c('v-card',{staticClass:"wr_grey_1 elevation-4 ma-3 comparison-table-wr",attrs:{"flat":""}},[_c('v-data-table',{staticClass:"wr_grey_1",attrs:{"disable-sort":"","headers":_vm.headers,"items":_vm.events,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.stage",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.stage))])]}},{key:"item.plan_start_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.plan_start_date)+" ")]}},{key:"item.plan_end_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.plan_end_date)+" ")]}},{key:"item.actual_start_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.actual_start_date)+" ")]}},{key:"item.actual_end_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.actual_end_date)+" ")]}},{key:"item.starting_status",fn:function(ref){
var item = ref.item;
return [(item.plan_start_date && item.actual_start_date)?_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":_vm.getStyle(item.plan_start_date, item.actual_start_date)}},[_vm._v(" "+_vm._s(_vm.$t( ("app." + (_vm.getTimeStatus( item.plan_start_date, item.actual_start_date ))) ))+" "),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v(_vm._s(_vm.$vuetify.icons.values[ _vm.getIcon(item.plan_start_date, item.actual_start_date) ]))])],1):_vm._e()]}},{key:"item.ending_status",fn:function(ref){
var item = ref.item;
return [(item.plan_end_date && item.actual_end_date)?_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":_vm.getStyle(item.plan_end_date, item.actual_end_date)}},[_vm._v(" "+_vm._s(_vm.$t( ("app." + (_vm.getTimeStatus( item.plan_end_date, item.actual_end_date ))) ))+" "),_c('v-icon',{attrs:{"right":!_vm.$vuetify.rtl,"left":_vm.$vuetify.rtl,"small":""}},[_vm._v(_vm._s(_vm.$vuetify.icons.values[ _vm.getIcon(item.plan_end_date, item.actual_end_date) ]))])],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }