<template>
	<v-card outlined class="mt-10">
		<v-card-text class="subtitle-1 font-weight-bold">
			See more from {{ fullName(current_student.user) }}
		</v-card-text>

		<v-card-text>
			<v-list class="pt-0">
				<v-list-item-group>
					<v-list-item
						v-for="(item, i) in items"
						:key="i"
						@click="proceed(item.route)"
					>
						<v-list-item-icon>
							<v-icon v-text="$vuetify.icons.values[item.icon]"></v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title
								class="subtitle-1"
								v-text="$t(item.text)"
							></v-list-item-title>
						</v-list-item-content>
						<v-list-item-action>
							<v-icon>{{
								$vuetify.icons.values[
									$vuetify.rtl ? "left_chevron" : "right_chevron"
								]
							}}</v-icon>
						</v-list-item-action>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
	name: "AdditionalAssignments",

	computed: {
		...mapState({
			current_student: (state) => state.student.current_student,
		}),

		...mapGetters({
			fullName: "user/fullName",
			isAdmin: "user/isAdmin",
		}),

		items() {
			return [
				{
					icon: "user",
					text: "app.meetings_summary",
					route: this.isAdmin
						? "AdminStudentMeetingSummary"
						: "StudentMeetingSummary",
				},
				{
					icon: "user",
					text: "app.interviews.title",
					route: this.isAdmin ? "AdminStudentInterviews" : "StudentInterviews",
				},
			];
		},
	},

	methods: {
		proceed(route_name) {
			this.$router.push({
				name: route_name,
			});
		},
	},
};
</script>

<style></style>
