<template>
	<v-card flat :height="map ? '900px' : '100%'" outlined>
		<!-- If map found -->
		<WizardMap v-if="map" v-model="map" :input="map"></WizardMap>

		<!-- Else -->
		<NoData
			v-else
			heading="app.student_not_created_wizard_map"
			:show_action="false"
		></NoData>
	</v-card>
</template>

<script>
import WizardMap from "@/components/shared/tools/wizardMindmap/Index.vue";
import NoData from "@/components/shared/NoData.vue";

export default {
	name: "WizardMindmapAssignment",

	props: {
		student_id: {
			required: true,
		},
	},

	data() {
		return {
			map: null,
		};
	},

	components: {
		WizardMap,
		NoData,
	},

	mounted() {
		this.getMap();
	},

	methods: {
		async getMap() {
			try {
				this.$loader.start();

				let response = await this.$store.dispatch("mentorBoard/getOfStudent", {
					category: this.$defines.SYSTEM_CATEGORY,
					type: this.$defines.WHITEBOARD_TYPES.WIZARD_MINDMAP,
					student_id: this.student_id,
				});

				if (response && response.length) {
					this.map = response[0];
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style lang="scss">
@import "@/assets/styles/buttonGroup.scss";
</style>
