<template>
	<v-card flat width="100%">
		<v-row v-if="list && list.length">
			<v-col sm="6" v-for="(keyword, index) in list" :key="index">
				<v-text-field
					readonly
					color="greenAccent2"
					background-color="white"
					hide-details
					:value="keyword.name"
					dense
					outlined
				>
					<template slot="prepend">
						<v-checkbox
							readonly
							v-model="keyword.selected"
							color="wr_blue_1"
							:on-icon="$vuetify.icons.values.check_outline"
						></v-checkbox>
					</template>
				</v-text-field>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
export default {
	name: "KeywordsAssignment",

	props: {
		student_id: {
			required: true,
		},
	},

	data() {
		return {
			list: null,
		};
	},

	mounted() {
		this.listKeywords();
	},

	methods: {
		async listKeywords() {
			try {
				this.$loader.start();

				this.list = await this.$store.dispatch("keyword/getOfStudent", {
					user_id: this.student_id,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style scoped>
>>> .v-input--selection-controls {
	margin: 0px;
	padding-top: 0px;
}
</style>
