var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-stepper',{staticClass:"elevation-0",attrs:{"alt-labels":""},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[(_vm.events.length)?_c('v-stepper-header',[_vm._l((_vm.events),function(event,index){return [_c('v-stepper-step',{key:index,class:{
					'cursor-wr': _vm.isClickable(event.module_id),
				},attrs:{"step":event.module_id,"color":"success","complete":event.module_id < _vm.value,"complete-icon":_vm.$vuetify.icons.values.check},on:{"click":function($event){_vm.isClickable(event.module_id)
						? _vm.$emit('set-current-module', event.module_id)
						: null}}},[_c('div',{staticClass:"body-1"},[_c('div',[_vm._v(" "+_vm._s(_vm.moment(event.start_date).format("DD/MM/YYYY"))+" ")]),_c('div',{class:[
							_vm.isCurrentOrCompleted(event.module_id)
								? ((event.color) + "--text")
								: '',
							'font-weight-bold',
							'title',
							{
								'text-decoration-underline':
									_vm.isAuthorizedEntity && event.module_id === _vm.selected_module,
							} ]},[_vm._v(" "+_vm._s(event.module)+" ")]),(_vm.isCurrentOrCompleted(event.module_id))?_c('div',{class:((_vm.isCurrentOrCompleted(event.module_id) ? 'black' : 'grey') + "--text")}):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.$t(("app." + (_vm.getModuleName(event.module_id)) + ".title")))+" ")])])]),(index + 1 < _vm.events.length)?_c('v-divider',{key:index + 'd',class:[
					'timeline-divider-wr',
					{
						green: event.module_id < _vm.value,
					} ]}):_vm._e()]})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }