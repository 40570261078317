<template>
	<div v-if="list && list.answers && list.answers.length">
		<!--
        The loop will check questionnaire's question type and create
        components accordingly.
       -->
		<v-row v-for="(questionnaire, index) in list.answers" :key="index">
			<component
				v-if="questionnaire"
				:is="decideComponent(questionnaire.question.type)"
				:questionnaire_id="questionnaire.id"
				@submit-answer="submitAnswer"
			>
			</component>
		</v-row>
	</div>
</template>

<script>
import TextField from "./TextField";
import TextArea from "./TextArea";
import CheckBox from "./CheckBox";
import RadioButton from "./RadioButton";
import Document from "./Document";
import { mapState } from "vuex";

export default {
	name: "FormGenerator",

	computed: {
		...mapState({
			list: (state) => state.questionnaire.list,
		}),
	},

	components: {
		TextField,
		TextArea,
		CheckBox,
		RadioButton,
		Document,
	},

	methods: {
		decideComponent(type) {
			/**
			 * From backend the input field types are having different names.
			 * Ex:- "radio_buttons" have name "rank", text_fields have name "open" etc.
			 * So, below code will decide the component name according code.
			 */
			let eligible_component = null;
			switch (type) {
				case "open":
					eligible_component = "TextField";
					break;
				case "large_open":
					eligible_component = "TextArea";
					break;
				case "rank":
				case "single_choice":
					eligible_component = "RadioButton";
					break;
				case "self_document":
					eligible_component = "Document";
					break;
				case "multiple_choice":
					eligible_component = "CheckBox";
					break;
			}
			return eligible_component;
		},

		submitAnswer(payload) {
			/**
			 * This payload is coming from loop which has modified
			 * values from their respected components.
			 */
			this.$store.dispatch("questionnaire/submitAnswer", {
				questionnaire_id: payload.questionnaire_id,
				answer: this.$xss.sanitize(payload.answer),
			});
		},
	},
};
</script>
