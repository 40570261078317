<template>
	<div class="question-board-parent-wr">
		<AppButton
			label="app.download"
			color="success"
			icon="download"
			:class="['ma-2']"
			@click="downloadObject()"
		></AppButton>

		<v-card
			v-if="board && board.data.length"
			class="wr_grey_1"
			flat
			id="downloadable-container-wr"
			height="100%"
		>
			<!-- In order to keep nodes position same in all cases, Each parent container
				 of vue resizable should placed at the top left so all nodes (vue resizable) can
				 start their dimensions calculation from top left. (see style of class .resizable)
		 -->

			<vue-resizable
				v-for="(item, index) in board.data"
				:key="index"
				:fitParent="true"
				class="resizable resizable-wr"
				ref="resizableComponent"
				dragSelector=".drag"
				:active="[]"
				:fit-parent="false"
				:width="item.width"
				:height="item.height"
				:max-width="250"
				:max-height="250"
				:min-width="150"
				:min-height="150"
				:top="item.top"
				:left="item.left"
			>
				<div class="drag-container-wr">
					<div
						class="amberAccent1"
						:id="item.id"
						:style="{ height: item.height }"
					>
						<v-textarea
							solo
							flat
							no-resize
							hide-details
							v-model="item.text"
							class="elevation-0"
							background-color="amberAccent1"
							:readonly="true"
						></v-textarea>

						<!-- Custom operations (add tags) -->
						<v-row class="opacity-background-wr pa-2" no-gutters>
							<v-col>
								<!-- otherwise display respected labels -->
								<AppButton
									:small="true"
									:rounded="false"
									:label="
										item.label == $defines.OPEN_QUESTION_VALUE
											? 'app.open_question'
											: 'app.closed_question'
									"
									:color="
										item.label == $defines.OPEN_QUESTION_VALUE
											? 'info'
											: 'error'
									"
								/>
							</v-col>
							<v-col class="cursor-wr text-end">
								<v-icon :color="item.fav ? 'error' : ''">{{
									$vuetify.icons.values[item.fav ? "heart" : "heart_outline"]
								}}</v-icon>
							</v-col>
						</v-row>
					</div>
				</div>
			</vue-resizable>
		</v-card>
	</div>
</template>

<script>
import { downloadImage } from "@/plugins/htmlToImage";

export default {
	name: "QuestionBoardExplore",

	props: {
		prop_board_id: {
			required: true,
		},
	},

	data() {
		return {
			board: null,
		};
	},

	mounted() {
		this.getBoard();
	},

	methods: {
		async getBoard() {
			try {
				this.$loader.start();

				this.board = await this.$store.dispatch("wizardMindmap/get", {
					uuid: this.prop_board_id,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		downloadObject() {
			try {
				this.$loader.start();

				// Reset the scale of container first
				let el = document.getElementById("downloadable-container-wr");
				if (!el) return;

				el.style.transform = "scale(1)";

				setTimeout(async () => {
					await downloadImage("#downloadable-container-wr");

					this.$loader.stop();
				}, 1000);
			} catch (error) {
				this.$announce.error(error);
				this.$loader.stop();
			}
		},
	},
};
</script>

<style scoped>
.question-board-parent-wr {
	position: relative;
}

.download-btn-wr {
	z-index: 1;
}
</style>
