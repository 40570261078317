import ArtPlayerDialog from "@/components/shared/ArtPlayerDialog.vue";

export const awsPlayerMixin = {
	data() {
		return {
			dialog: false,
		};
	},

	components: {
		ArtPlayerDialog,
	},

	methods: {
		parseChannel() {
			return this.recording_url.split("_")[1].split(".")[0];
		},

		async proceedToOpenPlayer() {
			try {
				this.$loader.start();

				await this.$store.dispatch("aws/makeS3ObjectPublic", {
					bucket: "weresearch",
					folder: this.parseChannel(),
					public: true,
				});

				this.dialog = true;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async proceedToClosePlayer() {
			try {
				this.$loader.start();

				this.dialog = false;

				await this.$store.dispatch("aws/makeS3ObjectPrivate", {
					bucket: "weresearch",
					folder: this.parseChannel(),
					public: false,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
