<template>
	<!-- Attachment property is represting that in which element's
		respect this dialog should open, we need to define this property
		due to full screen mode in mind map.
	-->
	<v-dialog
		:attach="attach"
		:value="dialog"
		scrollable
		width="550px"
		persistent
	>
		<AppDialog
			v-model="loading"
			:heading="{
				label: 'app.attach_url',
			}"
			:action="{
				label: 'app.save',
				event: 'confirm',
			}"
			@close="$emit('close')"
			@confirm="confirm()"
		>
			<template v-slot:dialog-content>
				<v-form ref="form">
					<v-text-field
						v-model="node.text"
						:label="$t('app.enter_title')"
						:rules="[rules.required]"
						color="greenAccent2"
						background-color="white"
						outlined
					>
					</v-text-field>

					<v-text-field
						v-model="node.attachment"
						:label="$t('app.enter_url')"
						:rules="[rules.required]"
						color="greenAccent2"
						background-color="white"
						outlined
					>
					</v-text-field>
				</v-form>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import rules from "@/utils/validation";
import AppDialog from "@/components/ui/AppDialog.vue";

export default {
	name: "AttachmentDialog",

	props: {
		dialog: {
			required: true,
			type: Boolean,
		},
		node: {
			required: true,
		},
		attach: {
			default: false,
		},
	},

	data() {
		return {
			rules,
			loading: false,
		};
	},

	components: {
		AppDialog,
	},

	methods: {
		confirm() {
			if (!this.$refs.form.validate()) return;

			this.loading = true;

			// arg1 = data
			let arg1 = {
				node: this.node,
			};
			// arg2 = callback (to stop loading when process completes)
			let arg2 = (reset = true) => {
				this.loading = false;
				if (reset) {
					this.$refs.form.reset();
				}
			};

			this.$emit("confirm", arg1, arg2);
		},
	},
};
</script>
