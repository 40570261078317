<template>
	<v-btn-toggle
		class="wr-btn-toggle"
		:borderless="true"
		:style="styleObj"
		dense
		tile
	>
		<v-btn
			v-tooltip="tooltipConfig(item.tooltip)"
			v-for="(item, index) in zoomActions"
			:key="index"
			@click="handleScalibility(item.value)"
			><v-icon>{{ $vuetify.icons.values[item.icon] }}</v-icon></v-btn
		>
	</v-btn-toggle>
</template>

<script>
export default {
	name: "ZoomController",

	props: {
		el: {
			required: true,
		},
	},

	data() {
		return {
			count: 0,
		};
	},

	computed: {
		zoomActions() {
			return [
				{
					icon: "plus",
					tooltip: "app.zoom_in",
					value: "in",
				},
				{
					icon: "refresh",
					tooltip: "app.reset",
					value: "reset",
				},
				{
					icon: "minus",
					tooltip: "app.zoom_out",
					value: "out",
				},
			];
		},

		styleObj() {
			return {
				bottom: 0,
			};
		},
	},

	methods: {
		tooltipConfig(text) {
			return {
				content: this.$t(text),
				placement: "top",
				targetClasses: ["it-has-a-tooltip"],
				offset: 8,
				classes: ["toggle_btn"],
			};
		},

		handleScalibility(value) {
			try {
				let el = document.querySelector(this.el);
				if (!el) return;

				switch (value) {
					case "in":
						if (this.count === 9) return;
						this.count++;
						break;
					case "out":
						if (this.count === 0) return;
						this.count--;
						break;
					case "reset":
						if (this.count === 0) return;
						this.count = 0;
						break;
				}

				el.style.zoom = 100 + this.count + "%";
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>
