<template>
	<v-chip-group>
		<v-chip
			v-for="tag in tags"
			:key="tag.id"
			:color="tag.color"
			class="rounded-0"
			:dark="isColorDark(tag.color)"
		>
			{{ tag.name }}
		</v-chip>
	</v-chip-group>
</template>

<script>
import { isColorDark } from "@/utils/helpers";

export default {
	name: "SourcesAssignmentTags",

	props: {
		tags: {
			required: true,
		},
	},

	data() {
		return {
			isColorDark,
		};
	},
};
</script>

<style></style>
