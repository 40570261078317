<template>
	<v-container fill-height fluid>
		<v-row justify="center">
			<v-col align="center">
				<v-card flat class="transparent">
					<v-card-text align="center">
						<v-img
							:src="require(`@/assets/images/error.png`)"
							width="140"
						></v-img>

						<v-card-text class="wr_indigo_2--text title font-weight-bold">
							{{ $t(heading) }}
						</v-card-text>

						<v-card-actions class="justify-center" v-if="show_action">
							<AppButton
								label="app.add_new"
								color="info"
								icon="plus"
								@click="$emit('init')"
							></AppButton>
						</v-card-actions>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: "NoData",

	props: {
		heading: {
			required: true,
		},

		show_action: {
			default: true,
		},
	},
};
</script>
