var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ps-5"},[(_vm.questionnaire_data && _vm.questionnaire_data.length)?_vm._l((_vm.questionnaire_data),function(questionnaire,index){return _c('v-row',{key:index},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{class:_vm.question_classes},[_vm._v(" "+_vm._s(index + 1)+".  "+_vm._s(questionnaire.question.name)+" ")]),_c('div',[(
							_vm.isArrayHavingItem(
								['open', 'large_open'],
								questionnaire.question.type
							)
						)?_c('div',{class:_vm.answer_classes},[_vm._v(" "+_vm._s(questionnaire.formated_answer)+" ")]):_vm._e(),(
							_vm.isArrayHavingItem(
								['rank', 'multiple_choice'],
								questionnaire.question.type
							)
						)?_c('div',{class:_vm.answer_classes},[_vm._v(" "+_vm._s(_vm.prepareChoiceAnswer(questionnaire))+" ")]):_vm._e(),(questionnaire.question.type === 'self_document')?_c('div',[(questionnaire.attachment)?_c('AppQuestionnaireImage',{attrs:{"source_url":("" + (_vm.$environments.base_api_url) + (questionnaire.attachment))}}):_vm._e(),(questionnaire.aws)?_c('AppQuestionnaireVideo',{attrs:{"recording_url":questionnaire.aws}}):_vm._e()],1):_vm._e()])])],1)}):_c('NoData',{attrs:{"heading":"app.questionnaire_incomplete_yet","show_action":false}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }