var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-btn-toggle',[_vm._l((_vm.majorProperties),function(property,index){return _c('v-menu',{key:'major' + index,attrs:{"attach":_vm.is_full_screen ? ("#resize-" + (_vm.item.id)) : false,"left":!_vm.$vuetify.rtl,"right":_vm.$vuetify.rtl,"close-on-content-click":false,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"white"}},'v-btn',attrs,false),on),[(property.icon_name)?_c('v-icon',{attrs:{"color":property.icon_color}},[_vm._v(_vm._s(_vm.$vuetify.icons.values[property.icon_name]))]):_c('span',{style:(property.name === 'color' ? _vm.colorClassObj : null)},[_vm._v(_vm._s(_vm._f("capitalize")(property.label)))])],1)]}}],null,true)},[_c('v-card',{attrs:{"width":"115","align":"center"}},[_c('v-card-text',{staticClass:"px-1"},[(_vm.isArrayHavingItem(['background', 'color'], property.name))?_c('span',_vm._l((property.options),function(color,index){return _c('div',{key:index,class:['ma-1 cursor-wr d-inline-block', color],style:({
							position: 'relative',
							width: '20px',
							height: '20px',
						}),on:{"click":function($event){return _vm.updateAttribute(property.name, color)}}},[(_vm.item[property.name] === color)?_c('v-icon',{staticClass:"colors-menu-wr",attrs:{"color":_vm.item[property.name] === 'black' ? 'white' : 'black',"small":""}},[_vm._v("$vuetify.icons.values.check")]):_vm._e()],1)}),0):_vm._e(),(_vm.isArrayHavingItem(['font', 'fontFamily'], property.name))?_c('span',_vm._l((property.options),function(option,index){
						var _obj;
return _c('div',{key:index,class:( _obj = {
							'font-weight-bold error--text': _vm.item[property.name] == option,
							'cursor-wr black--text pa-1': true
						}, _obj[option] = property.name === 'font', _obj ),style:({
							fontFamily: property.name == 'fontFamily' ? option : '',
						}),on:{"click":function($event){return _vm.updateAttribute(property.name, option)}}},[_vm._v(" "+_vm._s(option)+" ")])}),0):_vm._e()])],1)],1)}),_vm._l((_vm.minorProperties),function(property,index){return _c('v-btn',{key:'minor' + index,attrs:{"color":_vm.isArrayHavingItem(['bold', 'italic'], property.name) &&
			_vm.item[property.name]
				? 'greyLighten2'
				: 'white'},on:{"click":function($event){property.event_name
				? _vm.$emit(property.event_name)
				: _vm.updateAttribute(property.name, !_vm.item[property.name])}}},[_c('v-icon',[_vm._v(_vm._s(_vm.$vuetify.icons.values[property.name]))])],1)}),(_vm.item.attachment)?_c('v-btn',{staticClass:"text-lowercase",attrs:{"color":"white"}},[_c('a',{attrs:{"href":_vm.item.attachment,"target":"_blank"}},[_vm._v(_vm._s(_vm.displayShortenLink(_vm.item.attachment)))])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }