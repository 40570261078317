import { mapState, mapGetters } from "vuex";

export const formValidationMixin = {
	data() {
		return {
			is_fetching: true,
		};
	},

	computed: {
		...mapState({
			questionnaire_id: (state) => state.questionnaire.list.id,
			questionnaire: (state) => state.questionnaire.list,
		}),

		...mapGetters({
			userHasMentor: "user/hasMentor",
		}),

		isQuestionnaireDrafted() {
			return (
				this.questionnaire.status ==
				this.$defines.QUESTIONNAIRE_STATUS_DRAFT_STRING
			);
		},
	},

	methods: {
		isFormValid(parent_form_ref) {
			/**
			 * parent.$children[0] = FormGenerator.vue (Just child of this component)
			 * parent.$children[0].$children = All fields' forms. (Child components of FormGenerator.vue)
			 */
			let parent = this.$refs[parent_form_ref];
			if (
				parent.$children[0] == undefined ||
				parent.$children[0].$children == undefined
			) {
				return false;
			}

			let valid = true;
			let form_refs = [
				"textFieldForm",
				"textAreaForm",
				"radioButtonForm",
				"checkBoxForm",
				"documentForm",
			];

			// Outer loop - For all child forms.
			parent.$children[0].$children.forEach((form) => {
				// Inner loop - To check which child form is having which type of ref (from refs array).
				form_refs.forEach((ref_name) => {
					// If child form matched with ref then check this is validate or not.
					if (form.$refs.hasOwnProperty(ref_name)) {
						valid &=
							ref_name == "documentForm"
								? this.isDocumentValid()
								: form.$refs[ref_name].validate();
					}
				});
			});
			return valid;
		},

		isDocumentValid() {
			let questionnaire = this.questionnaire.answers.find((item) => {
				return item.question.type == "self_document";
			});

			return questionnaire == undefined ||
				questionnaire.question.required == "false"
				? true
				: questionnaire.question.required == "true" &&
						(questionnaire.attachment !== null || questionnaire.aws !== null);
		},

		async getQuestions(input_questionnaire_name, query_params = null) {
			try {
				this.$loader.start();

				await this.$store.dispatch("questionnaire/list", {
					questionnaire_name: input_questionnaire_name,
					query_params,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.is_fetching = false;
				this.$loader.stop();
			}
		},

		async submitQuestionnaire(form_ref) {
			let valid = await this.isFormValid(form_ref);

			if (!valid) {
				throw new Error("app.ntfy.err.fill_required_fields", {
					cause: "werCustom",
				});
			}

			// Submit questionnaire
			await this.$store.dispatch("questionnaire/submit", {
				questionnaire_id: this.questionnaire_id,
				status: this.$defines.QUESTIONNAIRE_STATUS_WAIT_FOR_APPROVAL_STRING,
			});
		},
	},
};
