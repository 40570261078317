<template>
	<div class="wr_grey_1 px-3">
		<template v-for="(source, index) in sources">
			<v-row no-gutters class="mt-6" :key="index">
				<v-col sm="1" class="display-2 wr_blue_1--text">
					<div>{{ index + 1 }}</div>
					<div>
						<v-checkbox
							v-if="shouldShowSelection && source.selected"
							readonly
							on-icon="$vuetify.icons.values.check_outline"
							rounded
							color="wr_blue_1"
							hide-details
							class="mb-4"
							:input-value="source.selected"
						></v-checkbox>
					</div>
				</v-col>
				<v-col sm="11">
					<v-card
						:class="{
							'border-wr': source.selected && shouldShowSelection,
							'elevation-2': true,
						}"
					>
						<v-card-text>
							<v-row no-gutters>
								<v-col sm="9">
									<v-row no-gutters>
										<v-col sm="4">
											<div>
												<u class="font-weight-bold black--text">{{
													source.title
												}}</u>
											</div>
										</v-col>

										<!-- Apply and create tags -->
										<v-col sm="7">
											<Tags
												v-if="
													!isGatherInfoRoute &&
														source.related_types &&
														source.related_types.length
												"
												:tags="source.related_types"
											></Tags>
										</v-col>
									</v-row>

									<div>
										<read-more
											v-if="source.description"
											:more-str="$t('app.read_more')"
											:text="source.description"
											:less-str="$t('app.read_less')"
											:max-chars="300"
											class="black--text"
										></read-more>
									</div>
								</v-col>

								<v-col sm="1">
									<v-divider class="wr-dash-divider mx-1" vertical></v-divider>
								</v-col>

								<!-- Addons -->
								<v-col sm="2">
									<Addons
										v-if="source.attachment || source.url"
										:attachment="source.attachment"
										:url="source.url"
									></Addons>
								</v-col>
							</v-row>
						</v-card-text>

						<template v-if="!isGatherInfoRoute">
							<!-- Show attach keywords -->
							<Keywords
								v-if="source.related_keywords && source.related_keywords.length"
								:related_keywords="source.related_keywords"
							/>
							<v-divider></v-divider>

							<!-- Documentation section -->
							<Documentation
								v-if="shouldShowDocumentation && source.source_type"
								:source="source"
							/>
							<v-divider></v-divider>

							<!-- Evaluation section -->
							<Evaluation
								v-if="shouldShowEvaluation && isSourceEvaluated(source)"
								:source="source"
							/>
						</template>
					</v-card>
				</v-col>
			</v-row>
		</template>
	</div>
</template>

<script>
import Tags from "@/components/shared/student/assignments/sources/Tags.vue";
import Addons from "@/components/shared/student/assignments/sources/Addons.vue";
import Keywords from "@/components/shared/student/assignments/sources/Keywords.vue";
import Documentation from "@/components/shared/student/assignments/sources/Documentation.vue";
import Evaluation from "@/components/shared/student/assignments/sources/Evaluation.vue";
import { researchRoutes } from "@/router/authority";
import { isArrayHavingItem } from "@/utils/helpers";
import { mapGetters } from "vuex";

export default {
	name: "SourcesAssignment",

	props: {
		student_id: {
			required: true,
		},
		prop_position: {
			required: true,
		},
	},

	data() {
		return {
			sources: null,
		};
	},

	components: {
		Tags,
		Addons,
		Keywords,
		Documentation,
		Evaluation,
	},

	computed: {
		...mapGetters({
			getRouteOfPosition: "research/getRouteOfPosition",
		}),

		$rop() {
			// $rop = abbrevation of "Route.Of.Position"
			return this.getRouteOfPosition(this.prop_position);
		},

		isGatherInfoRoute() {
			return this.$rop == researchRoutes.gatherInfo;
		},

		shouldShowSelection() {
			return this.$rop == researchRoutes.sourceSelection;
		},

		shouldShowDocumentation() {
			return isArrayHavingItem(
				[
					researchRoutes.sourceDocumentation,
					researchRoutes.sourceEvaluation,
					researchRoutes.sourceSelection,
				],
				this.$rop,
			);
		},

		shouldShowEvaluation() {
			return isArrayHavingItem(
				[researchRoutes.sourceEvaluation, researchRoutes.sourceSelection],
				this.$rop,
			);
		},
	},

	mounted() {
		this.getSourcesOfStudent();
	},

	methods: {
		async getSourcesOfStudent() {
			try {
				this.$loader.start();

				let response = await this.$store.dispatch("mentorSource/getOfStudent", {
					student_id: this.student_id,
				});
				if (response && response.length) {
					this.sources = response;
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		isSourceEvaluated(source) {
			try {
				return (
					source.sr_reliability +
						source.sr_info_quality +
						source.sr_beneficiality >
					0
				);
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>

<style scoped>
.border-wr {
	border: 2px solid var(--v-wr_blue_1-base) !important;
}
</style>
