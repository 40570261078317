<template>
	<v-col cols="12" v-if="questionnaire">
		<v-form ref="textAreaForm" lazy-validation>
			<!-- Question -->
			<div :class="question_styles">
				{{ question }}
				<!-- Information Icon -->
				<InfoIcon :message="questionnaire.question.description"></InfoIcon>
			</div>

			<!-- Show the keywords as suggestion -->
			<SuggestedKeywords
				:text="questionnaire.formated_answer"
				:current_questionnaire="current_questionnaire"
				:question_order="questionnaire.question.order"
				@push-to-answer="pushKeywordToAnswer($event, 'textAreaForm')"
			></SuggestedKeywords>

			<!-- Input field -->
			<div class="mt-2">
				<v-textarea
					outlined
					rows="3"
					color="greenAccent2"
					background-color="white"
					row-height="25"
					auto-grow
					:placeholder="$t('app.placeholder.type_answer')"
					v-model="questionnaire.formated_answer"
					:rules="
						questionnaire.question.required === 'true' ? [rules.required] : []
					"
					@blur="submitAnswerIfModified('textAreaForm')"
				>
					<span slot="append">
						<SpeechToText
							:text="questionnaire.formated_answer"
							@speechend="onSpeechEnd($event.text, 'textAreaForm')"
						></SpeechToText>
					</span>

					<span slot="append" class="ms-3">
						<TextToSpeech
							:text="questionnaire.formated_answer"
							slot="append"
						></TextToSpeech>
					</span>

					<span slot="append" class="ms-3">
						<Translator :text="questionnaire.formated_answer"></Translator>
					</span>
				</v-textarea>
			</div>
		</v-form>
	</v-col>
</template>

<script>
import { formActionsMixin } from "@/mixins/questionnaire/formActionsMixin.js";
import SpeechToText from "@/components/plugins/SpeechToText";
import SuggestedKeywords from "@/components/research/modules/_common/SuggestedKeywords.vue";
import TextToSpeech from "@/components/plugins/TextToSpeech.vue";
import Translator from "@/components/plugins/Translator.vue";

export default {
	name: "TextArea",

	mixins: [formActionsMixin],

	components: {
		SpeechToText,
		SuggestedKeywords,
		TextToSpeech,
		Translator,
	},
};
</script>
