<template>
	<v-col cols="12" v-if="questionnaire">
		<v-form ref="radioButtonForm" lazy-validation>
			<!-- Question -->
			<div :class="question_styles">
				{{ question }}
				<!-- Information Icon -->
				<InfoIcon :message="questionnaire.question.description"></InfoIcon>
			</div>

			<!-- Choices -->
			<div class="mt-2">
				<!-- Choose Theme and ChooseTopic would use this UI. -->
				<v-radio-group
					v-if="
						isArrayHavingItem(
							['ChooseThemeSummary', 'ChooseTopicSummary'],
							$route.name,
						)
					"
					v-model="questionnaire.formated_answer"
					:rules="
						questionnaire.question.required === 'true' ? [rules.required] : []
					"
				>
					<v-radio
						:label="option.name"
						color="greenAccent2"
						v-for="(option, index) in questionnaire.question.options"
						:key="index"
						:value="option.id"
						@click="submitAnswerIfModified('radioButtonForm')"
					></v-radio>
				</v-radio-group>

				<!-- Other's summary sections would use this UI -->
				<div v-else>
					<v-slider
						v-model="questionnaire.formated_answer"
						:min="0"
						:max="max.name"
						color="amberDarken1"
						track-color="amberDarken1"
						thumb-label="always"
						thumb-color="wr_indigo_2"
						class="align-center"
						@input="submitAnswerIfModified('radioButtonForm')"
						:rules="
							questionnaire.question.required === 'true' ? [rules.min(1)] : []
						"
					>
						<template v-slot:prepend>
							<span>{{ $t("app.not_at_all") }}</span>
						</template>
						<template v-slot:append>
							<span>{{ $t("app.very_much") }}</span>
						</template>
					</v-slider>
				</div>
			</div>
		</v-form>
	</v-col>
</template>

<script>
import { formActionsMixin } from "@/mixins/questionnaire/formActionsMixin.js";
import { isArrayHavingItem } from "@/utils/helpers";

export default {
	name: "RadioButton",

	mixins: [formActionsMixin],

	data() {
		return {
			isArrayHavingItem,
		};
	},

	computed: {
		min() {
			return this.questionnaire.question.options.reduce(function(prev, curr) {
				return parseInt(prev.name) < parseInt(curr.name) ? prev : curr;
			});
		},

		max() {
			return this.questionnaire.question.options.reduce(function(prev, curr) {
				return parseInt(prev.name) > parseInt(curr.name) ? prev : curr;
			});
		},
	},
};
</script>
