<template>
	<v-card v-if="input" flat height="100%" id="wizard-map-wr">
		<!-- Primary Actions -->
		<AppActionsTheme
			type="primary"
			:actions="primaryActions"
			@handle-function-call="handleFunctionCall"
		>
		</AppActionsTheme>

		<!-- Required Actions -->
		<AppActionsTheme
			v-if="isOwner && !isProcessMap"
			type="required"
			:actions="requiredActions"
			@handle-function-call="handleFunctionCall"
		>
		</AppActionsTheme>

		<!-- Zoom Actions -->
		<ZoomController el="#downloadable-container-wr"></ZoomController>

		<!--Container to operate on  -->
		<v-card
			v-if="input.data.length"
			outlined
			id="downloadable-container-wr"
			height="100%"
		>
			<vue-resizable
				v-for="(item, index) in input.data"
				:key="index"
				:fitParent="true"
				:width="item.width"
				:height="item.height"
				:left="item.left"
				:top="item.top"
				class="resizable resizable-wr"
				ref="resizableComponent"
				:id="`resize-${item.id}`"
				:dragSelector="isProcessMap ? '.empty' : '.drag-container-wr'"
				v-debounce:1s="manageTyping(item.id)"
				@resize:move="eHandler(item.id, $event)"
				@resize:end="eHandler(item.id, $event)"
				@drag:end="eHandler(item.id, $event)"
				:active="activeDimensions"
			>
				<div class="drag-container-wr">
					<!--
						Each node's v-menu is attached to node itself
						so it can open in full screen mode too
					 -->
					<v-menu
						offset-y
						:attach="is_full_screen ? `#resize-${item.id}` : false"
						:close-on-content-click="false"
					>
						<template v-slot:activator="{ on, attrs }">
							<div :id="item.id">
								<v-textarea
									solo
									flat
									tile
									v-on="on"
									no-resize
									hide-details
									v-bind="attrs"
									v-model="item.text"
									:height="item.height"
									:background-color="item.background"
									:color="item.color"
									:readonly="isProcessMap"
									:placeholder="
										$t('app.placeholder.type_text_here', {
											index: index,
										})
									"
									:class="[
										{
											'elevation-0': true,
											'font-weight-bold': item.bold,
											'font-italic': item.italic,
											[item.font]: true,
										},
									]"
								>
								</v-textarea>
							</div>
						</template>

						<Editor
							v-if="!isProcessMap"
							:item="item"
							:is_full_screen="is_full_screen"
							@delete-node="deleteNode(item.id)"
							@update-style="updateStyle(item, $event)"
							@prepare-attachment-process="prepareAttachmentProcess(item)"
						></Editor>
					</v-menu>
				</div>
			</vue-resizable>
		</v-card>

		<!-- Attachment dialog -->
		<AttachmentDialog
			v-model="attachment_dialog"
			:dialog="attachment_dialog"
			:node="selected_node"
			@close="attachment_dialog = false"
			@confirm="setAttachment"
		></AttachmentDialog>

		<!-- Confirmation box for delete operation -->
		<TheConfirmation
			v-model="delete_dialog"
			:dialog="delete_dialog"
			@cancel="delete_dialog = false"
			@confirm="proceedToDelete()"
		></TheConfirmation>

		<!-- Confirmation box for delete operation -->
		<ShareDialog
			v-model="share_dialog"
			:dialog="share_dialog"
			:uuid="input.uuid"
			@close="share_dialog = false"
			@confirm="share_dialog = false"
		></ShareDialog>
	</v-card>
</template>

<script>
import {
	findElIndex,
	isArrayHavingItem,
	generateRandomString,
} from "@/utils/helpers";
import {
	toolsWizardMapCollaborationMixin,
	toolsWizardMapDeletionMixin,
} from "@/mixins/tools/wizardMindmap/collaborationMixin";
import Editor from "@/components/shared/tools/wizardMindmap/Editor.vue";
import AttachmentDialog from "@/components/shared/tools/wizardMindmap/AttachmentDialog.vue";
import ZoomController from "@/components/plugins/ZoomController.vue";
import { downloadImage } from "@/plugins/htmlToImage";
import TheConfirmation from "@/components/layout/TheConfirmation";
import ShareDialog from "@/components/shared/ShareDialog.vue";
import AppActionsTheme from "@/components/ui/AppActionsTheme.vue";

export default {
	name: "WizardMindmapExplore",

	mixins: [toolsWizardMapCollaborationMixin, toolsWizardMapDeletionMixin],

	data() {
		return {
			is_full_screen: false,
			selected_node: {},
			delete_dialog: false,
			share_dialog: false,
			attachment_dialog: false,
		};
	},

	components: {
		Editor,
		AttachmentDialog,
		ZoomController,
		TheConfirmation,
		ShareDialog,
		AppActionsTheme,
	},

	computed: {
		isProcessMap() {
			return this.input.category === this.$defines.SYSTEM_CATEGORY;
		},

		activeDimensions() {
			return this.isProcessMap
				? []
				: ["r", "rb", "b", "lb", "l", "lt", "t", "rt"];
		},

		primaryActions() {
			return [
				{
					icon: "rectangle_outline",
					tooltip: "app.add_node",
					method: "addNode",
					condition: !this.isProcessMap,
				},
				// {
				// 	icon: "top_right_arrow",
				// 	tooltip: "app.arrow",
				// 	method: "addArrow",
				// },
				{
					icon: this.is_full_screen ? "exit_full_screen" : "full_screen",
					tooltip: this.is_full_screen
						? "app.exit_full_screen"
						: "app.full_screen",
					method: "toggleScreenMode",
					condition: true,
				},
				{
					icon: "download",
					tooltip: "app.download",
					method: "downloadObject",
					condition: true,
				},
				{
					icon: "delete",
					tooltip: "app.delete_board",
					method: "showConfirmationDialog",
					condition: this.isOwner && !this.isProcessMap,
				},
			];
		},

		requiredActions() {
			return [
				{
					icon: this.input.is_sharing_active ? "account_check" : "account_off",
					tooltip: this.input.is_sharing_active
						? "app.make_sharing_inactive"
						: "app.make_sharing_active",
					method: "updateSharingStatus",
				},
				{
					icon: "share",
					tooltip: "app.share",
					method: "openSharingDialog",
				},
			];
		},
	},

	created() {
		document.addEventListener("fullscreenchange", (event) => {
			// document.fullscreenElement will point to the element that
			// is in fullscreen mode if there is one. If there isn't one,
			// the value of the property is null.
			this.is_full_screen = document.fullscreenElement;
		});
	},

	methods: {
		openSharingDialog() {
			this.share_dialog = true;
		},

		async addNode() {
			try {
				let obj = {};
				obj.id = generateRandomString();
				obj.height = 50;
				obj.width = 260;
				obj.left = obj.top = 0;
				obj.eventName = obj.text = obj.attachment = "";
				obj.bold = obj.italic = obj.fav = false;
				obj.color = "white";
				obj.background = "info";
				obj.font = "headline";
				obj.fontFamily = "sans-serif";
				obj.is_title_node = false;

				await this.updateAtFirebase("add_node", {
					id: obj.id,
					data: obj,
				});

				// Save modifications to database
				await this.updateAtDatabase();

				// update style manually of newly added node.
				this.updateStyle(obj, {
					attribute: "color",
					value: obj.color,
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async deleteNode(node_id) {
			try {
				await this.updateAtFirebase("remove_node", {
					id: node_id,
				});

				// Save modifications to database
				this.updateAtDatabase();
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async updateStyle(item, payload) {
			try {
				item[payload.attribute] = payload.value;

				// We need to assign it manually because bind style is not working in textarea.
				if (isArrayHavingItem(["color", "fontFamily"], payload.attribute)) {
					let el = document.getElementById(item.id);
					if (el) {
						let text_field = el.getElementsByTagName("textarea")[0];
						text_field.style["fontFamily"] = payload.value;
						text_field.style["color"] = payload.value;
					}
				}

				await this.updateAtFirebase(payload.attribute, {
					id: item.id,
					[payload.attribute]: payload.value,
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},

		toggleScreenMode() {
			let el = document.getElementById("wizard-map-wr");
			if (el) {
				if (this.is_full_screen) {
					return document.exitFullscreen();
				}
				el.requestFullscreen();
				el.style.background = "#fff";
			}
		},

		prepareAttachmentProcess(payload) {
			try {
				this.$loader.start();

				this.selected_node.id = payload.id;
				this.selected_node.text = payload.text;
				this.selected_node.attachment = payload.attachment;

				this.attachment_dialog = true;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async setAttachment(payload, cb) {
			try {
				this.$loader.start();

				for (const attribute of ["text", "attachment"]) {
					await this.updateAtFirebase(attribute, {
						id: payload.node.id,
						[attribute]: payload.node[attribute],
					});
				}

				this.selected_node = {};

				cb();

				this.attachment_dialog = false;
			} catch (error) {
				cb(false);
				this.$announce.error(error);
			}
		},

		async showConfirmationDialog() {
			this.delete_dialog = true;
		},

		async downloadObject() {
			try {
				this.$loader.start();

				// Reset the scale of container first
				let el = document.getElementById("downloadable-container-wr");
				if (!el) return;

				el.style.transform = "scale(1)";

				await downloadImage("#downloadable-container-wr");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async updateSharingStatus(status) {
			try {
				this.$loader.start();

				this.input.is_sharing_active = !this.input.is_sharing_active;

				// Update board's status at firebase
				await this.updateAtFirebase("sharing_status", {
					status: this.input.is_sharing_active,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async proceedToDelete() {
			try {
				this.$loader.start();

				await this.executeDeletion(this.input.uuid, true);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.delete_dialog = false;
				this.$loader.stop();
			}
		},
	},
};
</script>
