import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import download from "downloadjs";
import Vue from "vue";

export const downloadImage = (selector) => {
	// Find element
	let el = document.querySelector(selector);

	// Before Download- Change element's background to White
	el.style.background = "white";

	return htmlToImage
		.toJpeg(el)
		.then(function(image) {
			// Download
			download(image, "wer-img-" + new Date().getTime() + ".jpeg");

			// After Download- Change background to normal
			el.style.background = null;

			return image;
		})
		.catch(function(error) {
			Vue.prototype.$announce.error(error);
		});
};
