<template>
	<AppQuestionnaire
		v-if="questionnaire_data"
		:prop_questionnaire="questionnaire_data"
	></AppQuestionnaire>
</template>

<script>
import AppQuestionnaire from "@/components/ui/AppQuestionnaire.vue";

export default {
	name: "QuestionnaireAssignment",

	props: {
		student_id: {
			required: true,
		},
		questionnaire_name: {
			required: true,
		},
	},

	data: () => ({
		questionnaire_data: null,
	}),

	components: {
		AppQuestionnaire,
	},

	watch: {
		questionnaire_name: {
			handler() {
				// clear previous data
				this.questionnaire_data = null;
				// get new data
				this.getQuestionnaire();
			},
			immediate: true,
		},
	},

	mounted() {
		this.getQuestionnaire();
	},

	methods: {
		async getQuestionnaire() {
			try {
				this.$loader.start();

				let response = await this.$store.dispatch(
					"mentorQuestionnaire/getOfStudent",
					{
						questionnaire_name: this.questionnaire_name,
						user_id: this.student_id,
					},
				);

				if (response && response.answers.length) {
					this.questionnaire_data = response.answers;
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
