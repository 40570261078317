<template>
	<v-expansion-panels flat>
		<v-expansion-panel>
			<v-expansion-panel-header class="pa-4">
				<template v-slot:actions>
					<v-icon
						:style="{ fontSize: '24px !important', color: 'blue !important' }"
						class="expansion-left-icon-wr"
						>$vuetify.icons.sources.arrow_down</v-icon
					>
				</template>
				<div class="expansion-left-icon-header-wr mx-2">
					<span class="font-weight-bold subtitle-1 ">{{
						$t("app.evaluation")
					}}</span>
				</div>
			</v-expansion-panel-header>

			<v-expansion-panel-content>
				<v-stepper v-model="current_step" vertical flat>
					<template v-for="(questionnaire, index) in evaluation_questions">
						<v-stepper-step
							:key="'s' + index"
							:complete="
								isCompleted(questionnaire.questions) && current_step > index + 1
							"
							:editable="index + 1 < current_step"
							edit-icon="$vuetify.icons.values.check"
							:step="index + 1"
							color="wr_indigo_2"
							class="subtitle-1 black--text font-weight-bold"
						>
							{{ $t(questionnaire.name) }}
							<small>{{ $t(questionnaire.subtitle) }}</small>
						</v-stepper-step>

						<v-stepper-content :step="index + 1" :key="'c' + index">
							<v-form>
								<div
									v-for="(question, queIndex) in questionnaire.questions"
									:key="'que-' + queIndex"
								>
									<div class="font-weight-bold">
										{{ $t(question.title) }}
									</div>
									<div v-if="question.subtitle" class="mt-1">
										{{ $t(question.subtitle) }}
									</div>

									<v-textarea
										v-if="question.type == 'text'"
										readonly
										rows="2"
										outlined
										dense
										class="mt-2"
										color="greenAccent2"
										background-color="white"
										:value="source[question.key]"
									></v-textarea>
									<p v-if="question.type == 'number'">
										<v-row no-gutters>
											<v-col>
												<v-slider
													:min="0"
													:max="10"
													step="1"
													color="amberDarken1"
													track-color="amberDarken1"
													thumb-label="always"
													thumb-color="wr_indigo_2"
													:value="source[question.key]"
													readonly
												>
													<template v-slot:prepend>
														<small>{{ $t("app.not_at_all") }}</small>
													</template>
													<template v-slot:append>
														<small>{{ $t("app.very_much") }}</small>
													</template>
												</v-slider>
											</v-col>
										</v-row>
									</p>
								</div>
							</v-form>
							<AppButton
								v-if="current_step != evaluation_questions.length"
								label="app.continue"
								color="wr_indigo_2"
								:dark="true"
								@click="current_step = current_step + 1"
							></AppButton>

							<AppButton
								v-if="current_step != 1"
								label="app.back"
								color="greyLighten2"
								:class="['ms-3']"
								@click="current_step = current_step - 1"
							></AppButton>
						</v-stepper-content>
					</template>
				</v-stepper>

				<!-- Evaluation answers -->
				<v-card outlined class="greyLighten4">
					<v-card-text>
						<v-row no-gutters>
							<v-col align="center" class="pt-3">
								{{ $t("app.source_evaluation.final_score.question_1.title") }}
								<v-btn depressed fab color="wr_indigo_2" small dark>
									<span class="headline">{{ source.sr_reliability }}</span>
								</v-btn>
							</v-col>
							<v-col align="center" class="pt-3">
								{{ $t("app.source_evaluation.final_score.question_2.title") }}
								<v-btn depressed fab color="wr_indigo_2" small dark>
									<span class="headline">{{ source.sr_info_quality }}</span>
								</v-btn>
							</v-col>
							<v-col align="center" class="pt-3">
								{{ $t("app.source_evaluation.final_score.question_3.title") }}
								<v-btn depressed fab color="wr_indigo_2" small dark>
									<span class="headline">{{ source.sr_beneficiality }}</span>
								</v-btn>
							</v-col>
							<v-col class="text-end">
								<v-btn
									depressed
									large
									fab
									color="greenAccent2"
									class="font-weight-bold overline"
								>
									{{ $t("app.score") }}
									<br />
									{{ evaluationScore }}
								</v-btn>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
import { EVALUATION_QUESTIONS } from "@/utils/helpers/source";

export default {
	name: "SourcesAssignmentEvaluation",

	props: {
		source: {
			required: true,
		},
	},

	data() {
		return {
			current_step: 1,
		};
	},

	computed: {
		evaluation_questions() {
			return EVALUATION_QUESTIONS;
		},

		evaluationScore: function() {
			return (
				this.source.sr_reliability +
				this.source.sr_info_quality +
				this.source.sr_beneficiality
			);
		},
	},

	methods: {
		isCompleted(questions) {
			var output = questions.find(
				(question) =>
					this.source[question.key] == null || this.source[question.key] == 0,
			);
			return output == undefined;
		},
	},
};
</script>

<style scoped>
>>> .v-timeline-item__inner-dot .v-icon {
	color: var(--v-amberDarken1-base);
}
.card-wr {
	border: 1px solid var(--v-error-base) !important;
}
</style>
