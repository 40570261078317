<template>
	<v-col cols="12" v-if="questionnaire">
		<v-form ref="checkBoxForm" lazy-validation>
			<!-- Question -->
			<div :class="question_styles">
				{{ question }}
				<!-- Information Icon -->
				<InfoIcon :message="questionnaire.question.description"></InfoIcon>
			</div>
			<!-- Choices -->
			<div class="mt-2">
				<v-checkbox
					color="greenAccent2"
					:label="option.name"
					:rules="
						questionnaire.question.required === 'true' ? [rules.required] : []
					"
					v-for="(option, index) in questionnaire.question.options"
					:key="index"
					:value="option.id"
					v-model="questionnaire.formated_answer"
					@click="submitAnswerIfModified('checkBoxForm')"
				></v-checkbox>
			</div>
		</v-form>
	</v-col>
</template>

<script>
import { formActionsMixin } from "@/mixins/questionnaire/formActionsMixin.js";

export default {
	name: "CheckBox",

	mixins: [formActionsMixin],
};
</script>
