<template>
	<v-btn-toggle>
		<!-----------------------------------------------------------
			MAJOR PROPERTIES (background, color, fontSize, fontFamily)
		------------------------------------------------------------>
		<!-- The menu should attached to it's parent node -->
		<v-menu
			v-for="(property, index) in majorProperties"
			:key="'major' + index"
			:attach="is_full_screen ? `#resize-${item.id}` : false"
			:left="!$vuetify.rtl"
			:right="$vuetify.rtl"
			:close-on-content-click="false"
			offset-x
		>
			<template v-slot:activator="{ on, attrs }">
				<v-btn color="white" v-bind="attrs" v-on="on">
					<!-- If background, and font size property -->
					<v-icon v-if="property.icon_name" :color="property.icon_color">{{
						$vuetify.icons.values[property.icon_name]
					}}</v-icon>

					<!-- If font family and color property -->
					<span
						v-else
						:style="property.name === 'color' ? colorClassObj : null"
						>{{ property.label | capitalize }}</span
					>
				</v-btn>
			</template>
			<v-card width="115" align="center">
				<v-card-text class="px-1">
					<!-- If background and color property -->
					<span
						v-if="isArrayHavingItem(['background', 'color'], property.name)"
					>
						<div
							v-for="(color, index) in property.options"
							:key="index"
							:class="['ma-1 cursor-wr d-inline-block', color]"
							:style="{
								position: 'relative',
								width: '20px',
								height: '20px',
							}"
							@click="updateAttribute(property.name, color)"
						>
							<v-icon
								v-if="item[property.name] === color"
								:color="item[property.name] === 'black' ? 'white' : 'black'"
								small
								class="colors-menu-wr"
								>$vuetify.icons.values.check</v-icon
							>
						</div>
					</span>

					<!-- If font size and font family property -->
					<span v-if="isArrayHavingItem(['font', 'fontFamily'], property.name)">
						<div
							v-for="(option, index) in property.options"
							:key="index"
							:class="{
								'font-weight-bold error--text': item[property.name] == option,
								'cursor-wr black--text pa-1': true,
								[option]: property.name === 'font',
							}"
							:style="{
								fontFamily: property.name == 'fontFamily' ? option : '',
							}"
							@click="updateAttribute(property.name, option)"
						>
							{{ option }}
						</div>
					</span>
				</v-card-text>
			</v-card>
		</v-menu>

		<!---------------------------------------------------
			MINOR PROPERTIES (bold, italic, attachment, delete)
		---------------------------------------------------->
		<v-btn
			v-for="(property, index) in minorProperties"
			:key="'minor' + index"
			:color="
				isArrayHavingItem(['bold', 'italic'], property.name) &&
				item[property.name]
					? 'greyLighten2'
					: 'white'
			"
			@click="
				property.event_name
					? $emit(property.event_name)
					: updateAttribute(property.name, !item[property.name])
			"
			><v-icon>{{ $vuetify.icons.values[property.name] }}</v-icon></v-btn
		>

		<!-- Display attachment link seperately -->
		<v-btn color="white" class="text-lowercase" v-if="item.attachment">
			<a :href="item.attachment" target="_blank">{{
				displayShortenLink(item.attachment)
			}}</a>
		</v-btn>
	</v-btn-toggle>
</template>

<script>
import { replaceTextWithDots, isArrayHavingItem } from "@/utils/helpers";

const COLORS = [
	"white",
	"grey",
	"brown",
	"orange",
	"yellow",
	"green",
	"cyan",
	"blue",
	"purple",
	"pink",
	"red",
	"teal",
	"black",
];

const FONT_SIZES = [
	"caption",
	"subtitle-2",
	"body-2",
	"button",
	"body-1",
	"subtitle-1",
	"overline",
	"title",
	"headline",
];

const FONT_FAMILIES = [
	"serif",
	"sans-serif",
	"monospace",
	"cursive",
	"fantasy",
	"system-ui",
	"ui-serif",
	"ui-sans-serif",
	"ui-monospace",
	"ui-rounded",
	"emoji",
	"math",
	"fangsong",
];

export default {
	name: "WizardMindmapEditor",

	props: {
		item: {
			required: true,
			type: Object,
		},
		is_full_screen: {
			type: Boolean,
		},
	},

	data() {
		return {
			isArrayHavingItem,
		};
	},

	computed: {
		colorClassObj() {
			return {
				fontSize: "20px",
				borderBottom: `3px solid var(--v-${this.item.color}-base)`,
				height: "27px",
				fontWeight: "bold",
				color: "black",
			};
		},

		majorProperties() {
			return [
				{
					name: "background",
					icon_name: "rectangle_filled",
					icon_color: this.item.background,
					options: COLORS,
				},
				{
					name: "fontFamily",
					label: this.item.fontFamily,
					options: FONT_FAMILIES,
				},
				{
					name: "font",
					icon_name: "size",
					icon_color: null,
					options: FONT_SIZES,
				},
				{
					name: "color",
					label: "A",
					options: COLORS,
				},
			];
		},

		minorProperties() {
			return [
				{
					name: "bold",
				},
				{
					name: "italic",
				},
				{
					name: "attachment",
					event_name: "prepare-attachment-process",
				},
				{
					name: "delete",
					event_name: "delete-node",
				},
			];
		},
	},

	methods: {
		displayShortenLink(link) {
			return replaceTextWithDots(link, 20, link.length);
		},

		updateAttribute(attribute, value) {
			this.$emit("update-style", {
				attribute,
				value,
			});
		},
	},
};
</script>

<style scoped>
.colors-menu-wr {
	position: absolute;
	right: 6%;
}
</style>
