<template>
	<v-dialog :value="dialog" scrollable width="650px" persistent>
		<AppDialog
			:heading="{
				label: label,
			}"
			:action="{
				label: 'app.okay',
				event: 'okay',
			}"
			@close="$emit('close')"
			@okay="$emit('close')"
		>
			<template v-slot:dialog-content>
				<v-row no-gutters>
					<v-col class="text-truncate">
						<v-card height="55" flat outlined tile class="copy-text-card-wr">
							<v-card-text class="py-3 black--text subtitle-1">
								{{ uuid }}
							</v-card-text>
						</v-card>
					</v-col>
					<v-col sm="4">
						<CopyToClipboard
							:message="uuid"
							:use_icon="false"
						></CopyToClipboard>
					</v-col>
				</v-row>

				<div v-if="desc" v-html="$t(desc)" class="mt-7 subtitle-1"></div>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import AppDialog from "@/components/ui/AppDialog.vue";
import CopyToClipboard from "@/components/plugins/CopyToClipboard";

export default {
	name: "ShareDialog",

	props: {
		dialog: {
			required: true,
			type: Boolean,
		},
		uuid: {
			required: true,
		},
		label: {
			default: "app.share",
		},
		desc: {
			default: null,
		},
	},

	computed: {
		value() {
			return `${this.$environments.base_app_url}/research/tools/wizard_mind_map/${this.uuid}`;
		},
	},

	components: {
		CopyToClipboard,
		AppDialog,
	},

	methods: {
		copy() {
			let copied = this.$clipboard(this.value);
			if (!copied) {
				throw new Error("app.ntfy.err.copying_failed", {
					cause: "werCustom",
				});
			}
			this.$announce.success("copied successfully");
		},
	},
};
</script>

<style scoped>
.card-wr {
	border-color: black;
}
</style>
