<template>
	<v-card flat :height="map_id ? '900px' : '100%'" class="wr_grey_1">
		<!-- If map found -->
		<StandardMindmap v-if="map_id" :prop_map_id="map_id"></StandardMindmap>

		<!-- Else -->
		<NoData
			v-else
			heading="app.student_not_standard_mind_map"
			:show_action="false"
		></NoData>
	</v-card>
</template>

<script>
import StandardMindmap from "@/components/shared/tools/standardMindmap/Index.vue";
import NoData from "@/components/shared/NoData.vue";

export default {
	name: "StandardMindmapAssignment",

	props: {
		student_id: {
			required: true,
		},
	},

	data() {
		return {
			map_id: null,
		};
	},

	components: {
		StandardMindmap,
		NoData,
	},

	mounted() {
		this.getMap();
	},

	methods: {
		async getMap() {
			try {
				this.$loader.start();

				let response = await this.$store.dispatch(
					"mentorMindmap/getOfStudent",
					{
						student_id: this.student_id,
					},
				);

				if (response.data && response.data.length) {
					this.map_id = response.data[0].id;
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
