<template>
	<v-dialog :value="dialog" scrollable width="1000" persistent>
		<AppDialog
			:heading="{
				label: 'app.recording_preview',
			}"
			@close="$emit('close')"
		>
			<template v-slot:dialog-content>
				<div class="artplayer-app"></div>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import Artplayer from "artplayer";
import Hls from "hls.js";
import AppDialog from "@/components/ui/AppDialog.vue";

export default {
	name: "ArtPlayerDialog",

	props: {
		dialog: {
			required: true,
		},
		source_url: {
			required: true,
		},
	},

	components: {
		AppDialog,
	},

	mounted() {
		var art = new Artplayer({
			container: ".artplayer-app",
			url: `https://weresearch.s3.eu-central-1.amazonaws.com/${this.parseChannel()}/${
				this.source_url
			}`,
			isLive: false,
			muted: false,
			autoplay: true,
			pip: false,
			autoSize: true,
			autoMini: true,
			screenshot: false,
			setting: false,
			loop: false,
			flip: true,
			rotate: true,
			playbackRate: true,
			aspectRatio: true,
			fullscreen: false,
			fullscreenWeb: true,
			miniProgressBar: true,
			localVideo: true,
			localSubtitle: true,
			mutex: true,
			backdrop: true,
			settings: [],
			quality: [],
			customType: {
				m3u8: function(video, url) {
					var hls = new Hls();
					hls.loadSource(url);
					hls.attachMedia(video);
				},
			},
		});
	},

	methods: {
		parseChannel() {
			return this.source_url.split("_")[1].split(".")[0];
		},
	},
};
</script>

<style scoped>
>>> .artplayer-app {
	height: 500px;
	width: 100%;
}
</style>
