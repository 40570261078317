<template>
	<v-card flat class="wr_grey_1 pa-1">
		<!-- Header -->
		<div
			class="headline font-weight-bold"
			v-html="
				$t('app.student_progress_params', {
					student_name: fullName(current_student.user),
				})
			"
		></div>

		<!-- A particular module timeline -->
		<v-card outlined class="mt-10">
			<v-card-text class="subtitle-1 font-weight-bold">
				{{ $t("app.current_module_progress") }}
				<span class="ps-4">
					{{ $t("app.current_module_progress_instructions") }}
				</span>
			</v-card-text>
			<AppModuleProgress
				v-if="latestAvailableStage"
				v-model="latestAvailableStage.value"
				:events="moduleEvents"
				:selected_stage="selected_stage"
				@set-current-stage="selected_stage = $event"
			></AppModuleProgress>
		</v-card>

		<!-- All Modules timeline -->
		<v-card outlined class="mt-10">
			<v-card-text class="subtitle-1 font-weight-bold">
				{{ $t("app.module_wise_progress") }}
				<span class="ps-4">
					{{ $t("app.module_progress_instructions") }}
				</span>
			</v-card-text>
			<AppModulesProgress
				v-if="latestAvailableStage"
				v-model="latestAvailableStage.module_id"
				:events="modulesEvents"
				:selected_module="selected_module"
				@set-current-module="selected_module = $event"
			></AppModulesProgress>
		</v-card>

		<!-- current module's current stage's data -->
		<div id="student-assignment">
			<StudentAssignments
				v-if="selected_stage"
				:position="selected_stage"
				:selected_module="selected_module"
			></StudentAssignments>
		</div>

		<!-- current module's current stage's data -->
		<AdditionalAssignments></AdditionalAssignments>
	</v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import StudentAssignments from "@/components/shared/student/assignments/Index.vue";
import AppModuleProgress from "@/components/ui/AppModuleProgress.vue";
import AppModulesProgress from "@/components/ui/AppModulesProgress.vue";
import AdditionalAssignments from "@/components/shared/student/assignments/additional/Index.vue";

export default {
	name: "StudentOverview",

	data() {
		return {
			panel1: [],
			panel2: [0],
			selected_stage: null,
			selected_module: null,
		};
	},

	components: {
		AppModuleProgress,
		AppModulesProgress,
		StudentAssignments,
		AdditionalAssignments,
	},

	watch: {
		selected_module(newVal, oldVal) {
			if (newVal !== oldVal) {
				this.selected_stage = null;
			}
		},

		selected_stage(newVal, oldVal) {
			if (newVal && newVal != oldVal) {
				this.scrollTo();
			}
		},
	},

	computed: {
		...mapState({
			current_student: (state) => state.student.current_student,
		}),

		...mapGetters({
			fullName: "user/fullName",
		}),

		latestAvailableStage() {
			return this.$store.getters["user/getLatestAvailableStage"](
				this.current_student.available_steps,
			);
		},

		modulesEvents() {
			return this.$store.getters["planAndManage/getModulePlan"]();
		},

		moduleEvents() {
			let module_id = this.selected_module
				? this.selected_module
				: this.latestAvailableStage.module_id;
			return this.$store.getters["planAndManage/getModuleStagePlan"](
				"M" + module_id,
			);
		},
	},

	mounted() {
		this.getUserPlan();
	},

	methods: {
		async getUserPlan() {
			try {
				this.$loader.start();

				await this.$store.dispatch("planAndManage/getUserPlan", {
					user_id: this.$route.params.id,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		scrollTo() {
			this.$nextTick(() => {
				document.getElementById("student-assignment").scrollIntoView({
					behavior: "smooth",
				});
			});
		},
	},
};
</script>

<style scoped>
>>> .student-progress-wr {
	border: 1px solid !important;
}
>>> .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate)
	.v-icon {
	color: white !important;
	font-size: 30px !important;
}
</style>
