<template>
	<v-stepper v-model="value" alt-labels class="elevation-0">
		<v-stepper-header v-if="events.length">
			<template v-for="(event, index) in events">
				<v-stepper-step
					:key="index"
					:step="event.module_id"
					color="success"
					:complete="event.module_id < value"
					:complete-icon="$vuetify.icons.values.check"
					@click="
						isClickable(event.module_id)
							? $emit('set-current-module', event.module_id)
							: null
					"
					:class="{
						'cursor-wr': isClickable(event.module_id),
					}"
				>
					<div class="body-1">
						<div>
							{{ moment(event.start_date).format("DD/MM/YYYY") }}
						</div>
						<div
							:class="[
								isCurrentOrCompleted(event.module_id)
									? `${event.color}--text`
									: '',
								'font-weight-bold',
								'title',
								{
									'text-decoration-underline':
										isAuthorizedEntity && event.module_id === selected_module,
								},
							]"
						>
							{{ event.module }}
						</div>
						<div
							v-if="isCurrentOrCompleted(event.module_id)"
							:class="
								`${
									isCurrentOrCompleted(event.module_id) ? 'black' : 'grey'
								}--text`
							"
						></div>
						<div>
							{{ $t(`app.${getModuleName(event.module_id)}.title`) }}
						</div>
					</div>
				</v-stepper-step>
				<v-divider
					v-if="index + 1 < events.length"
					:key="index + 'd'"
					:class="[
						'timeline-divider-wr',
						{
							green: event.module_id < value,
						},
					]"
				></v-divider>
			</template>
		</v-stepper-header>
	</v-stepper>
</template>

<script>
import moment from "moment";
import { mapGetters, mapState } from "vuex";

export default {
	name: "AppModulesProgress",

	props: {
		value: {},
		events: {
			required: true,
		},
		selected_module: {
			required: false,
		},
	},

	data() {
		return {
			moment,
		};
	},

	computed: {
		...mapGetters({
			isMentor: "user/isMentor",
			isAdmin: "user/isAdmin",
		}),

		...mapState({
			modules: (state) => state.research.modules,
		}),

		isAuthorizedEntity() {
			return this.isMentor || this.isAdmin;
		},
	},

	methods: {
		isCurrentOrCompleted(stage) {
			return stage <= this.value;
		},

		isClickable(module_id) {
			return this.isAuthorizedEntity && this.isCurrentOrCompleted(module_id);
		},

		getModuleName(module_value) {
			try {
				return this.$store.getters["research/getModuleName"](module_value);
			} catch (error) {
				this.$announce.error(error);
			}
		},

		getModuleChildren(module_id) {
			let result = this.modules.find((item) => item.value == module_id);
			if (!result) return;

			let arr = [];
			result.children.forEach((child) => {
				arr.push(child.title);
			});

			return arr;
		},
	},
};
</script>
