import { findFromArray, isArrayHavingItem } from "@/utils/helpers";

export const formAnswersMixin = {
	data() {
		return {
			question_styles: {
				"greyDarken4--text": true,
				"subtitle-1": true,
			},
			answer_styles: [
				"mt-2",
				"ms-8",
				"body-1 font-weight-thin",
				"white-space-pre-line-wr",
			],
		};
	},

	props: {
		questionnaire: {
			required: true,
		},
	},

	computed: {
		question() {
			return `
                ${this.questionnaire.question.order}
                .\xa0
                ${this.questionnaire.question.name}
            `;
		},

		answer() {
			return this.questionnaire.formated_answer;
		},
	},

	methods: {
		getNameOfAnswer(id) {
			try {
				if (
					isArrayHavingItem(
						["ChooseThemeSummary", "ChooseTopicSummary"],
						this.$route.name,
					)
				) {
					let result = findFromArray(
						this.questionnaire.question.options,
						"id",
						id,
					);

					return result !== undefined ? result.name : null;
				}
				return this.questionnaire.answer;
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
