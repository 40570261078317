<template>
	<v-icon
		color="error"
		class="ms-3 mb-1 cursor-wr icon-size-wr"
		v-tooltip="{
			content: message,
			placement: $vuetify.rtl ? 'left' : 'right',
			targetClasses: ['it-has-a-tooltip'],
			offset: 20,
			classes: ['info'],
		}"
	>
		$vuetify.icons.values.info_circle_filled</v-icon
	>
</template>

<script>
export default {
	name: "FormFieldInfoIcon",

	props: {
		message: {
			required: true,
		},
	},
};
</script>

<style lang="scss">
@import "@/assets/styles/tooltip.scss";
</style>

<style scoped>
.icon-size-wr {
	font-size: 1.8125rem !important; /** 29px */
}
</style>
