<template>
	<v-card class="wr_grey_1 mt-5" flat height="100%">
		<template v-if="isStageCompleted && isResponsibleStage">
			<v-tabs
				v-model="current_tab"
				active-class="black--text font-weight-bold"
				:left="!$vuetify.rtl"
				slider-color="error"
				background-color="wr_grey_1"
			>
				<template v-for="(option, index) in options">
					<v-tab
						v-if="option.condition"
						:key="index"
						:href="`#${option.name}`"
						class="title"
					>
						{{ $t(option.name) }}
					</v-tab>
				</template>
			</v-tabs>

			<v-tabs-items
				v-model="current_tab"
				class="wr_grey_1 mt-10 full-height-wr"
			>
				<template v-for="(option, index) in options">
					<v-tab-item v-if="option.condition" :key="index" :value="option.name">
						<component
							v-if="option.component_name"
							:is="option.component_name"
							:student_id="current_student.id"
							:questionnaire_name="relatedQuestionnaire"
							:review_questionnaire_name="reviewQuestionnaire"
							:prop_module="selected_module"
							:prop_position="position"
							:show_heading="false"
						></component>
					</v-tab-item>
				</template>
			</v-tabs-items>
		</template>
	</v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { isArrayHavingItem } from "@/utils/helpers";
import * as assignmentComponents from "@/components/shared/student/assignments/index.js";
import { researchRoutes } from "@/router/authority";

export default {
	name: "StudentAssignments",

	props: {
		// this prop is refering to the stage selected by user
		position: {
			required: true,
		},
		selected_module: {
			required: true,
		},
	},

	data() {
		return {
			current_tab: "app.answers",
		};
	},

	components: {
		...assignmentComponents,
	},

	computed: {
		...mapState({
			current_student: (state) => state.student.current_student,
		}),

		...mapGetters({
			getRouteOfPosition: "research/getRouteOfPosition",
		}),

		$rop() {
			// $rop = abbrevation of "Route.Of.Position"
			return this.getRouteOfPosition(this.position);
		},

		$r() {
			// $r = abbrevation of "ResearchRoutes"
			return researchRoutes;
		},

		summaryRoutes() {
			return [
				this.$r.defineQuestionSummary,
				this.$r.gatherSourcesSummary,
				this.$r.writeSummary,
				this.$r.reflectJourneySummary,
				this.$r.presentSummary,
			];
		},

		sourceRoutes() {
			return [
				this.$r.gatherInfo,
				this.$r.createSourceList,
				this.$r.sourceDocumentation,
				this.$r.sourceEvaluation,
				this.$r.sourceSelection,
			];
		},

		researchPaperRoutes() {
			return [this.$r.researchPaper, this.$r.xl9baq_Poster];
		},

		latestAvailableStage() {
			return this.$store.getters["user/getLatestAvailableStage"](
				this.current_student.available_steps,
			);
		},

		isStageCompleted() {
			if (!this.latestAvailableStage) {
				throw new Error("app.ntfy.err.next_step_not_found", {
					cause: "werCustom",
				});
			}
			/**
			 * If selected stage is not completed but in working mode
			 * and falls between below defined stages, show the results
			 * still. Else, show only if stage is completed.
			 */
			if (
				this.position == this.latestAvailableStage.value &&
				isArrayHavingItem(
					[
						this.sourceRoutes,
						...this.researchPaperRoutes,
						// FIXME:: For now, only show reflect journey summary
						this.$r.reflectJourneySummary,
					],
					this.$rop,
				)
			) {
				return true;
			}
			return this.position < this.latestAvailableStage.value;
		},

		isResponsibleStage() {
			// Those are the positions which are containing any resources.
			return isArrayHavingItem(
				[
					this.$r.chooseTheme,
					this.$r.chooseTopic,
					this.$r.chooseQuestion,
					this.$r.cc3hhl_StandardMap,
					this.$r.bkvgew_StandardMap,
					this.$r.er4tfv_StandardMap,
					this.$r.planSearchStrategy,
					this.$r.achievementsAndChallenges,
					...this.researchPaperRoutes,
					...this.sourceRoutes,
					...this.summaryRoutes,
				],
				this.$rop,
			);
		},

		options() {
			return [
				{
					name: "app.answers",
					component_name: "Questionnaire",
					condition: isArrayHavingItem(
						[
							this.$r.chooseTheme,
							this.$r.chooseTopic,
							this.$r.chooseQuestion,
							this.$r.achievementsAndChallenges,
							...this.summaryRoutes,
						],
						this.$rop,
					),
				},
				{
					name: "app.standard_mind_map",
					component_name: "StandardMap",
					condition: isArrayHavingItem(
						[
							this.$r.chooseTopic,
							this.$r.cc3hhl_StandardMap,
							this.$r.bkvgew_StandardMap,
							this.$r.er4tfv_StandardMap,
						],
						this.$rop,
					),
				},
				{
					name: "app.wizard_mind_map",
					component_name: "WizardMap",
					condition: this.$rop === this.$r.chooseTopic,
				},
				{
					name: "app.boards",
					component_name: "QuestionBoard",
					condition: this.$rop === this.$r.chooseQuestion,
				},
				{
					name: "app.progress",
					component_name: "PlanningComparison",
					condition: isArrayHavingItem(this.summaryRoutes, this.$rop),
				},
				{
					name: "app.keywords",
					component_name: "Keywords",
					condition: this.$rop == this.$r.planSearchStrategy,
				},
				{
					name: "app.sources",
					component_name: "Sources",
					condition: isArrayHavingItem(this.sourceRoutes, this.$rop),
				},
				{
					name: "app.research_paper.title",
					component_name: "ResearchPaper",
					condition: isArrayHavingItem(this.researchPaperRoutes, this.$rop),
				},
				{
					name: "app.write_review",
					component_name: "Review",
					condition: isArrayHavingItem(
						[
							this.$r.cc3hhl_StandardMap,
							this.$r.sourceEvaluation,
							this.$r.presentSummary,
						],
						this.$rop,
					),
				},
			];
		},

		relatedQuestionnaire() {
			switch (this.$rop) {
				case this.$r.chooseTheme:
					return this.$defines.QUESTIONNAIRE.q10;
				case this.$r.chooseTopic:
					return this.$defines.QUESTIONNAIRE.q30;
				case this.$r.chooseQuestion:
					return this.$defines.QUESTIONNAIRE.q50;
				case this.$r.defineQuestionSummary:
					return this.$defines.QUESTIONNAIRE.q60;
				case this.$r.gatherSourcesSummary:
					return this.$defines.QUESTIONNAIRE.q70;
				case this.$r.writeSummary:
					return this.$defines.QUESTIONNAIRE.q80;
				case this.$r.achievementsAndChallenges:
					return this.$defines.QUESTIONNAIRE.q81;
				case this.$r.reflectJourneySummary:
					return this.$defines.QUESTIONNAIRE.q90;
				case this.$r.presentSummary:
					return this.$defines.QUESTIONNAIRE.q100;
				default:
					return null;
			}
		},

		reviewQuestionnaire() {
			switch (this.$rop) {
				case this.$r.cc3hhl_StandardMap:
					return this.$defines.QUESTIONNAIRE.q110;
				case this.$r.sourceEvaluation:
					return this.$defines.QUESTIONNAIRE.q120;
				case this.$r.presentSummary:
					return this.$defines.QUESTIONNAIRE.q130;
			}
		},
	},
};
</script>
