var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wr_grey_1 px-3"},[_vm._l((_vm.sources),function(source,index){return [_c('v-row',{key:index,staticClass:"mt-6",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"display-2 wr_blue_1--text",attrs:{"sm":"1"}},[_c('div',[_vm._v(_vm._s(index + 1))]),_c('div',[(_vm.shouldShowSelection && source.selected)?_c('v-checkbox',{staticClass:"mb-4",attrs:{"readonly":"","on-icon":"$vuetify.icons.values.check_outline","rounded":"","color":"wr_blue_1","hide-details":"","input-value":source.selected}}):_vm._e()],1)]),_c('v-col',{attrs:{"sm":"11"}},[_c('v-card',{class:{
						'border-wr': source.selected && _vm.shouldShowSelection,
						'elevation-2': true,
					}},[_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"sm":"9"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"sm":"4"}},[_c('div',[_c('u',{staticClass:"font-weight-bold black--text"},[_vm._v(_vm._s(source.title))])])]),_c('v-col',{attrs:{"sm":"7"}},[(
												!_vm.isGatherInfoRoute &&
													source.related_types &&
													source.related_types.length
											)?_c('Tags',{attrs:{"tags":source.related_types}}):_vm._e()],1)],1),_c('div',[(source.description)?_c('read-more',{staticClass:"black--text",attrs:{"more-str":_vm.$t('app.read_more'),"text":source.description,"less-str":_vm.$t('app.read_less'),"max-chars":300}}):_vm._e()],1)],1),_c('v-col',{attrs:{"sm":"1"}},[_c('v-divider',{staticClass:"wr-dash-divider mx-1",attrs:{"vertical":""}})],1),_c('v-col',{attrs:{"sm":"2"}},[(source.attachment || source.url)?_c('Addons',{attrs:{"attachment":source.attachment,"url":source.url}}):_vm._e()],1)],1)],1),(!_vm.isGatherInfoRoute)?[(source.related_keywords && source.related_keywords.length)?_c('Keywords',{attrs:{"related_keywords":source.related_keywords}}):_vm._e(),_c('v-divider'),(_vm.shouldShowDocumentation && source.source_type)?_c('Documentation',{attrs:{"source":source}}):_vm._e(),_c('v-divider'),(_vm.shouldShowEvaluation && _vm.isSourceEvaluated(source))?_c('Evaluation',{attrs:{"source":source}}):_vm._e()]:_vm._e()],2)],1)],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }