<template>
	<div>
		<v-card outlined class="ms-8 mt-2" width="150" @click="openImagePreview()">
			<v-img :src="source_url" height="150" contain>
				<template v-slot:placeholder>
					<ImageLoader></ImageLoader>
				</template>
			</v-img>
		</v-card>
	</div>
</template>

<script>
import ImageLoader from "@/components/plugins/ImageLoader";

export default {
	name: "AppQuestionnaireImage",

	props: {
		source_url: {
			required: true,
		},
		propStyle: {
			type: Object,
		},
	},

	components: {
		ImageLoader,
	},

	methods: {
		openImagePreview() {
			try {
				window.open(this.source_url);
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>

<style scoped>
.image-container-wr {
	border: 1px solid var(--v-greyLighten2-base) !important;
}
</style>
