<template>
	<v-stepper :value="value" alt-labels class="elevation-0 transparent">
		<v-stepper-header v-if="events.length">
			<template v-for="(event, index) in events">
				<v-stepper-step
					:key="index"
					:step="event.position"
					:color="getRelevantColor(event.module_id)"
					:complete="isCompleted(event.position)"
					:complete-icon="$vuetify.icons.values.check"
					@click="
						isClickable(event.position)
							? $emit('set-current-stage', event.position)
							: null
					"
					:class="{
						'cursor-wr': isClickable(event.position),
					}"
				>
					<div class="body-1">
						{{ event.plan_start_date + "-" + event.plan_end_date }}
						<div
							:class="[
								event.position <= value
									? `${getRelevantColor(event.module_id)}--text`
									: '',
								'font-weight-bold',
								'button',
								{
									'text-decoration-underline black--text':
										isAuthorizedEntity && event.position === selected_stage,
								},
							]"
						>
							{{ event.stage }}
						</div>
					</div>
				</v-stepper-step>
				<v-divider
					v-if="index + 1 < events.length"
					:key="index + 'd'"
					:class="[
						'timeline-divider-wr',
						isCompleted(event.position) ? 'green' : '',
					]"
				></v-divider>
			</template>
		</v-stepper-header>
	</v-stepper>
</template>

<script>
import { mapGetters } from "vuex";
import { isArrayHavingItem } from "@/utils/helpers";
import { researchRoutes } from "@/router/authority";

export default {
	name: "AppModuleProgress",

	props: {
		// value prop is refering the "user/latestAvailableStage"
		value: {
			required: true,
		},
		events: {
			required: true,
		},
		selected_stage: {
			required: false,
		},
	},

	computed: {
		...mapGetters({
			isMentor: "user/isMentor",
			isAdmin: "user/isAdmin",
			getRouteOfPosition: "research/getRouteOfPosition",
		}),

		isAuthorizedEntity() {
			return this.isMentor || this.isAdmin;
		},
	},

	methods: {
		isCompleted(stage) {
			return stage < this.value;
		},

		isClickable(position) {
			return this.isAuthorizedEntity && this.isCurrentOrCompleted(position);
		},

		isCurrentOrCompleted(stage) {
			/**
			 * If selected stage is not completed but in working mode
			 * and falls between below defined stages, show the results
			 * still. Else, show only if stage is completed.
			 */
			if (
				stage == this.value &&
				isArrayHavingItem(
					[
						researchRoutes.gatherInfo,
						researchRoutes.createSourceList,
						researchRoutes.sourceDocumentation,
						researchRoutes.sourceEvaluation,
						researchRoutes.sourceSelection,
						researchRoutes.researchPaper,
						researchRoutes.xl9baq_Poster,
						// FIXME:: For now, only show reflect journey summary
						researchRoutes.reflectJourneySummary,
					],
					this.getRouteOfPosition(stage),
				)
			) {
				return true;
			}

			return this.isCompleted(stage);
		},

		getRelevantColor(module_id) {
			return this.$store.getters["research/getModuleColor"](module_id);
		},
	},
};
</script>
