<template>
	<v-card v-if="map" height="100%" flat :id="map.id" class="wr_grey_1">
		<v-card
			width="97%"
			height="100%"
			class="map"
			:id="`map-${map.id}`"
			outlined
			:style="{ ...prop_style }"
		></v-card>
	</v-card>
</template>

<script>
import { mapGetters } from "vuex";
import MindElixir, { E } from "mind-elixir";
import { isArrayHavingItem } from "@/utils/helpers";

export default {
	name: "StandardMindmapExplore",

	props: {
		prop_map_id: {
			required: false,
		},
		prop_style: {
			required: false,
		},
		show_fav_icon: {
			required: false,
			default: false,
		},
		map_data: {
			required: false,
		},
		readonly_mode: {
			default: false,
		},
	},

	data() {
		return {
			map: null,
			mind: null,
		};
	},

	mounted() {
		this.getMap();
	},

	computed: {
		...mapGetters({
			locale: "globals/getLocale",
		}),

		nonAccessConditions() {
			// This condition should apply only for tools route.
			/**
			 * FIXME:: For now (for ofek client) make process map editable in tools
			 * section and uneditable at mentor dashboard.
			 */
			return (
				// (this.map.category === "system" &&
				// 	this.$route.name === "StandardMindmapExplore") ||
				this.$route.name === "StudentOverview" ||
				this.$route.name === "StandardMapWork" ||
				this.readonly_mode
			);
		},
	},

	methods: {
		async getMap() {
			try {
				this.$loader.start();

				// Get map via API
				if (this.prop_map_id) {
					this.map = await this.$store.dispatch("standardMindmap/get", {
						id: this.prop_map_id,
					});
				}
				// Get map via props
				if (this.map_data) {
					this.map = structuredClone(this.map_data);
				}
				// Initialize map with mind map library after DOM load
				this.$nextTick(async () => {
					if (this.map) {
						await this.initializeMap();
					}
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async initializeMap() {
			let options = {
				el: `#map-${this.map.id}`,
				showFavIcon: this.show_fav_icon,
				direction: 2, // Set position of nodes both side (Left and Right)
				data: this.map.data,
				locale: this.locale,
				draggable: true,
				editable: !this.nonAccessConditions,
				contextMenu: !this.nonAccessConditions,
				contextMenuOption: {
					focus: false,
					link: !this.nonAccessConditions,
					up: !this.nonAccessConditions,
					down: !this.nonAccessConditions,
				},
				toolBar: true,
				nodeMenu: !this.nonAccessConditions,
				keypress: true,
				allowUndo: false,
				primaryLinkStyle: 2,
				primaryNodeVerticalGap: 15, // 25
				primaryNodeHorizontalGap: 15, // 65
			};

			this.mind = new MindElixir(options);
			this.$nextTick(async () => {
				this.mind.init();

				// Take care of locale
				if (this.locale === "he") {
					await this.manageRTLStyle(this.map.id);
				}
			});

			this.mind.bus.addListener("operation", async (operation) => {
				// Save map when the following operation have fired
				if (
					isArrayHavingItem(
						[
							"moveNode",
							"removeNode",
							"editTags",
							"editIcons",
							"insertSibling",
							"addChild",
							"finishEdit",
							"editStyle",
							"favUpdated",
							"moveUpNode",
							"moveDownNode",
							"moveChildNodesAfter",
							"finishNotesEditing",
						],
						operation.name,
					)
				) {
					await this.saveMap();

					this.$emit("operation-performed", operation);
				}
			});
		},

		manageRTLStyle(map_id) {
			// Apply RTL direction to required elements
			let parentElement = document.getElementById(map_id);
			if (!parentElement) return;

			// All input elements
			let inputs = parentElement.getElementsByTagName("input");
			for (var i = 0; i < inputs.length; i++) {
				inputs[i].style.direction = "rtl";
			}

			// All Nodes
			let nodes = parentElement.querySelectorAll(".map-container tpc");
			for (var i = 0; i < nodes.length; i++) {
				nodes[i].style.direction = "rtl";
			}
		},

		async saveMap() {
			try {
				if (!this.map) return;

				if (this.prop_map_id) {
					await this.$store.dispatch("standardMindmap/save", {
						id: this.map.id,
						data: this.mind.getAllData(),
					});
				}

				this.$emit("on-map-saved", {
					data: this.mind.getAllData(),
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},

	beforeDestroy() {
		if (this.prop_map_id) {
			this.saveMap();
		}
	},
};
</script>

<style scoped>
.map {
	border: 2px solid !important;
}
>>> .map-container {
	direction: ltr !important;
}
>>> #nm-tag {
	display: none !important;
}
>>> #nm-icon {
	display: none !important;
}
</style>
