<template>
	<div class="ms-8 mt-2">
		<v-img
			width="120"
			contain
			class="cursor-wr"
			:src="require('@/assets/images/video.png')"
			@click="proceedToOpenPlayer()"
		></v-img>

		<ArtPlayerDialog
			v-if="dialog"
			v-model="dialog"
			:dialog="dialog"
			:source_url="recording_url"
			@close="proceedToClosePlayer()"
		></ArtPlayerDialog>
	</div>
</template>

<script>
import { awsPlayerMixin } from "@/mixins/awsPlayerMixin.js";

export default {
	name: "QuestionnaireVideo",

	props: {
		recording_url: {
			required: true,
		},
	},

	mixins: [awsPlayerMixin],
};
</script>
