<template>
	<v-row class="fill-height ma-0" align="center" justify="center">
		<v-progress-circular
			indeterminate
			color="greyLighten2"
		></v-progress-circular>
	</v-row>
</template>

<script>
export default {
	name: "ImageLoader",
};
</script>
