<template>
	<vue-dropzone
		ref="myVueDropzone"
		id="dropzone"
		:options="dropOptions"
		:useCustomSlot="true"
		@vdropzone-success="onUploadSuccess"
		@vdropzone-error="onUploadError"
		@vdropzone-removed-file="onUploadedDocumentRemoved"
		@vdropzone-mounted="onDropzoneMounted"
		@vdropzone-sending="sendingEvent"
		:style="{
			height: `${height}`,
		}"
	>
		<slot name="dropzone-display-content"></slot>
	</vue-dropzone>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { buildHeaders } from "@/services/api/manager";
import { getFileNameFromURL } from "@/utils/helpers";

export default {
	name: "Uploader",

	props: {
		upload_url: {
			required: true,
		},
		document_url: {
			required: false,
		},
		should_disable: {
			default: false,
		},
		append_data: {
			default: null,
		},
		// FIXME::temporary prop for observations feature
		dummy: {
			default: false,
		},
		param_name: {
			default: "attachment",
		},
		height: {
			required: false,
		},
	},

	data() {
		return {
			// FIXME:: remove "dummy" var after prepared API.
			dropOptions: {
				url: this.upload_url,
				method: this.dummy ? "post" : "put",
				headers: this.dummy
					? { "My-Awesome-Header": "header value" }
					: buildHeaders().headers,
				maxFilesize: 15, // MB
				maxFiles: 1,
				addRemoveLinks: true,
				destroyDropzone: false,
				thumbnailMethod: "contain",
				resizeMethod: "contain",
				acceptedFiles: "image/*,application/pdf,.mp3,.mp4,.xlsx,.doc,.docx",
				paramName: this.param_name, // Uploaded document will be send on this key
				dictRemoveFile: "Remove",
			},
			is_component_destoying: false,
		};
	},

	components: {
		vueDropzone: vue2Dropzone,
	},

	created() {
		this.$eventBus.$on("enable-uploading", (paylod) => {
			try {
				if (this.$refs.myVueDropzone) {
					this.$refs.myVueDropzone.enable();
				}
			} catch (error) {
				this.$announce.error(error);
			}
		});
	},

	methods: {
		setImageHeight() {
			let el = document.querySelector(".dz-image");
			if (!el) return;

			el.style.height = this.height;
		},

		onDropzoneMounted() {
			try {
				if (this.should_disable && this.$refs.myVueDropzone) {
					this.$refs.myVueDropzone.disable();
					return;
				}
				/**
				 * If any attachment is present already then on mount, get the attachment
				 * from server and display it in dropzone.
				 */
				if (this.document_url) {
					this.$loader.start();

					fetch(this.document_url)
						.then((res) => res.blob()) // Gets the response and returns it as a blob
						.then((blob) => {
							if (this.$refs.myVueDropzone) {
								// Add file manually with file properties
								this.$refs.myVueDropzone.manuallyAddFile(
									{
										name: getFileNameFromURL(this.document_url),
										type: blob.type,
										size: blob.size,
									},
									this.document_url,
								);

								this.setImageHeight();
							}
						});
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		sendingEvent(file, xhr, formData) {
			if (this.append_data == null) {
				return;
			}
			for (const [key, value] of Object.entries(this.append_data)) {
				formData.append(key, value);
			}
		},

		async onUploadSuccess(file, response) {
			try {
				this.setImageHeight();

				this.$emit("upload-success", {
					response: response,
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},

		onUploadError(file, message, xhr) {
			try {
				throw new Error(message, {
					cause: "werCustom",
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},

		onUploadedDocumentRemoved(file, error, xhr) {
			try {
				/**
				 * In vue dropzone when component is being destroyed, all the uploaded
				 * files will destroy too, so if component is destroying, do not
				 * proceed for file removal.
				 *
				 */
				if (this.is_component_destoying) {
					return;
				}
				this.$emit("uploaded-document-removed");
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},

	beforeDestroy() {
		this.is_component_destoying = true;
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("enable-uploading");
	},
};
</script>

<style lang="scss">
@import "@/assets/styles/dropzone.scss";
</style>
