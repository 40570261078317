<template>
	<div class="text-center">
		<v-icon x-large>$vuetify.icons.values.attachment</v-icon>
		<div>
			<a
				:href="attachment ? `${$environments.base_api_url}${attachment}` : url"
				target="_blank"
				>{{
					attachment ? $t("app.attachment_added") : $t("app.website_added")
				}}</a
			>
		</div>
	</div>
</template>

<script>
export default {
	name: "SourcesAssignmentAddons",

	props: {
		attachment: {
			required: true,
		},
		url: {
			required: true,
		},
	},
};
</script>

<style></style>
