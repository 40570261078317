<template>
	<v-col cols="12" v-if="questionnaire">
		<v-form ref="documentForm" lazy-validation>
			<!-- Question -->
			<div :class="question_styles">
				{{ question }}
				<!-- Information Icon -->
				<InfoIcon :message="questionnaire.question.description"></InfoIcon>
			</div>

			<v-row align="center" justify="center" class="fill-height mt-5">
				<!-----------------------------------------------
        Recording using system's cam (Agora)
        ---------------------------------------------->
				<v-col>
					<v-hover v-slot:default="{ hover }">
						<v-card
							height="190"
							align="center"
							:class="['custom-card-wr cursor-wr', { 'elevation-3': hover }]"
							@click="proceedToRecord()"
						>
							<v-card-text>
								<v-img
									:src="require('@/assets/images/video-camera.png')"
									width="100"
								>
								</v-img>
							</v-card-text>
							<v-card-text class="pt-0 subtitle-1 greyDarken4--text">
								{{
									recording_url
										? $t("app.video_recorded_successfully")
										: $t("app.take_self_video")
								}}

								<template v-if="recording_url">
									<AppButton
										label="app.play"
										icon="play"
										color="secondary"
										:prop_class="['ms-2']"
										@click="proceedToOpenPlayer()"
									></AppButton>
									<AppButton
										label="app.delete"
										icon="delete"
										color="greyLighten2"
										:prop_class="['ms-2']"
										@click="RemoveRecording()"
									></AppButton>
								</template>
							</v-card-text>
						</v-card>
					</v-hover>
				</v-col>
				<!-----------------------------------------------
          Upload document from system
        ---------------------------------------------->
				<v-col>
					<v-hover v-slot:default="{ hover }">
						<v-card
							height="190"
							align="center"
							:class="['custom-card-wr', { 'elevation-3': hover }]"
						>
							<v-card height="190" flat>
								<Uploader
									:should_disable="recording_url"
									:upload_url="uploadUrl"
									:document_url="documentUrl"
									height="190px"
									@upload-success="onAfterUploadSuccess"
									@uploaded-document-removed="onAfterDocumentRemoved()"
								>
									<!-- "should_disable" is a prop for dropzone to verify that if recording has
                been done already, then make dropzone disable for uploading.
           -->
									<template v-slot:dropzone-display-content>
										<v-card
											flat
											align="center"
											height="190px"
											@click="proceedToUpload()"
										>
											<v-card-text>
												<v-img
													:src="require('@/assets/images/upload.png')"
													width="100"
												>
												</v-img>
											</v-card-text>

											<v-card-text
												v-html="$t('app.upload_or_drag_file')"
												class="pt-0 subtitle-1 greyDarken4--text"
											>
											</v-card-text>
										</v-card>
									</template>
								</Uploader>
							</v-card>
						</v-card>
					</v-hover>
				</v-col>
			</v-row>
		</v-form>

		<ArtPlayerDialog
			v-if="dialog"
			v-model="dialog"
			:dialog="dialog"
			:source_url="recording_url"
			@close="proceedToClosePlayer()"
		></ArtPlayerDialog>
	</v-col>
</template>

<script>
import { formActionsMixin } from "@/mixins/questionnaire/formActionsMixin.js";
import { awsPlayerMixin } from "@/mixins/awsPlayerMixin.js";
import Uploader from "@/components/plugins/Uploader";

export default {
	name: "Document",

	mixins: [formActionsMixin, awsPlayerMixin],

	data() {
		return {
			base_api_url: this.$environments.base_api_url,
		};
	},

	components: {
		Uploader,
	},

	computed: {
		uploadUrl() {
			return `${this.base_api_url}/user/question/${this.questionnaire_id}/`;
		},

		documentUrl() {
			return this.questionnaire.attachment
				? `${this.base_api_url}${this.questionnaire.attachment}`
				: null;
		},

		recording_url() {
			return this.questionnaire.aws;
		},
	},

	methods: {
		showRestrictionMessage() {
			throw new Error("app.ntfy.err.either_upload_or_record", {
				cause: "werCustom",
			});
		},

		onAfterDocumentRemoved() {
			try {
				this.$store.dispatch("questionnaire/submitAnswer", {
					questionnaire_id: this.questionnaire.id,
					attachment: null,
					answer: null,
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},

		onAfterUploadSuccess(payload) {
			try {
				this.$store.commit("questionnaire/SET_ANSWER", payload.response);
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async proceedToUpload() {
			try {
				if (this.recording_url) {
					this.showRestrictionMessage();
				}
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async proceedToRecord() {
			try {
				// If document already uploaded
				if (this.questionnaire.attachment) {
					this.showRestrictionMessage();
				}
				// Push for recording, with questionnaire
				if (!this.recording_url) {
					// Before pushing to Agora route, generate the channel
					let res = await this.$store.dispatch("agora/generateToken");
					this.$router.push({
						name: "AgoraRecordingPage",
						params: {
							questionnaire_id: this.questionnaire_id,
							channel_name: res.channelName,
						},
					});
				}
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async RemoveRecording() {
			try {
				this.$loader.start();

				let channel = this.parseChannel();

				// Remove recording from S3
				await this.$store.dispatch("aws/DeleteS3Object", {
					bucket: "weresearch",
					folder: channel,
				});

				// Remove recording from questionnaire answer
				await this.$store.dispatch("questionnaire/submitAnswer", {
					questionnaire_id: this.questionnaire.id,
					aws: null,
				});

				// After removing recording, the dropzone should be enable to upload.
				// this.$eventBus.$emit("enable-uploading");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style scoped>
.custom-card-wr:hover {
	border: 3px solid var(--v-greenAccent2-base);
}

.coming-soon-btn-wr {
	position: absolute;
	top: 2%;
	left: 1%;
	z-index: 1;
}
</style>
