var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-stepper',{staticClass:"elevation-0 transparent",attrs:{"value":_vm.value,"alt-labels":""}},[(_vm.events.length)?_c('v-stepper-header',[_vm._l((_vm.events),function(event,index){return [_c('v-stepper-step',{key:index,class:{
					'cursor-wr': _vm.isClickable(event.position),
				},attrs:{"step":event.position,"color":_vm.getRelevantColor(event.module_id),"complete":_vm.isCompleted(event.position),"complete-icon":_vm.$vuetify.icons.values.check},on:{"click":function($event){_vm.isClickable(event.position)
						? _vm.$emit('set-current-stage', event.position)
						: null}}},[_c('div',{staticClass:"body-1"},[_vm._v(" "+_vm._s(event.plan_start_date + "-" + event.plan_end_date)+" "),_c('div',{class:[
							event.position <= _vm.value
								? ((_vm.getRelevantColor(event.module_id)) + "--text")
								: '',
							'font-weight-bold',
							'button',
							{
								'text-decoration-underline black--text':
									_vm.isAuthorizedEntity && event.position === _vm.selected_stage,
							} ]},[_vm._v(" "+_vm._s(event.stage)+" ")])])]),(index + 1 < _vm.events.length)?_c('v-divider',{key:index + 'd',class:[
					'timeline-divider-wr',
					_vm.isCompleted(event.position) ? 'green' : '' ]}):_vm._e()]})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }