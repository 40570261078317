<template>
	<v-card v-if="questionnaire" flat class="wr_grey_1">
		<v-card-text>
			<!-- If questionnaire is in draft mode then show form -->
			<v-form ref="mentorReviewQuestionnaireForm" v-if="isQuestionnaireDrafted">
				<FormGenerator></FormGenerator>
			</v-form>
			<!-- else show only answers of form -->
			<FormResult v-else></FormResult>
		</v-card-text>

		<v-card-actions v-if="isQuestionnaireDrafted" class="mt-4">
			<AppButton
				label="app.save"
				color="greenAccent2"
				icon="save"
				@click="submit()"
			></AppButton>
		</v-card-actions>
	</v-card>
</template>

<script>
import FormGenerator from "@/components/plugins/formGenerator/Index";
import FormResult from "@/components/plugins/formResult/Index";
import { formValidationMixin } from "@/mixins/questionnaire/formValidaionMixin.js";
import { mapGetters } from "vuex";

export default {
	name: "WriteReview",

	mixins: [formValidationMixin],

	props: {
		student_id: {
			required: true,
		},
		review_questionnaire_name: {
			required: true,
		},
	},

	components: {
		FormGenerator,
		FormResult,
	},

	watch: {
		review_questionnaire_name(oldVal, newVal) {
			this.fetchQuestionnaire();
		},
	},

	mounted() {
		this.fetchQuestionnaire();
	},

	methods: {
		fetchQuestionnaire() {
			this.getQuestions(this.review_questionnaire_name, {
				user_id: this.student_id,
			});
		},

		async submit() {
			try {
				this.$loader.start();

				await this.submitQuestionnaire("mentorReviewQuestionnaireForm");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
