<template>
	<v-btn-toggle
		:class="['wr-btn-toggle', { 'flex-column': isPrimaryType }]"
		:style="styleObj"
		:borderless="true"
		dense
		tile
	>
		<template v-for="(item, index) in actions">
			<v-btn
				v-if="
					!item.hasOwnProperty('condition') ||
						(item.hasOwnProperty('condition') && item.condition)
				"
				:key="index"
				v-tooltip="tooltipConfig(item.tooltip)"
				@click="$emit('handle-function-call', item.method)"
			>
				<v-icon>{{ $vuetify.icons.values[item.icon] }}</v-icon>
			</v-btn>
		</template>
	</v-btn-toggle>
</template>

<script>
export default {
	name: "AppActionsTheme",

	props: {
		actions: {
			required: true,
		},
		type: {
			required: true,
		},
	},

	data() {
		return {
			primary_pos: this.$vuetify.rtl ? "left" : "right",
			required_pos: "bottom",
			access_pos: "top",
		};
	},

	computed: {
		styleObj() {
			if (this.isPrimaryType) {
				return {
					[this.$vuetify.rtl ? "right" : "left"]: 0,
				};
			}

			if (this.isRequiredType) {
				return {
					[this.$vuetify.rtl ? "left" : "right"]: 0,
				};
			}

			if (this.isAccessType) {
				return {
					bottom: 0,
					[this.$vuetify.rtl ? "left" : "right"]: 0,
				};
			}
		},

		isPrimaryType() {
			return this.type === "primary";
		},

		isRequiredType() {
			return this.type === "required";
		},

		isAccessType() {
			return this.type === "access";
		},
	},

	methods: {
		tooltipConfig(text) {
			return {
				content: this.$t(text),
				placement: this.isPrimaryType
					? this.primary_pos
					: this.isRequiredType
					? this.required_pos
					: this.access_pos,
				targetClasses: ["it-has-a-tooltip"],
				offset: 8,
				classes: ["toggle_btn"],
			};
		},
	},
};
</script>

<style lang="scss">
@import "@/assets/styles/tooltip.scss";
</style>
