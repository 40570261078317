<template>
	<div v-if="showSuggestions">
		<v-expand-transition v-if="keywords.length">
			<v-slide-group show-arrows>
				<v-slide-item v-for="(keyword, index) in keywords" :key="index">
					<v-chip
						class="me-2"
						color="info"
						:dark="isExactMatch(text, keyword)"
						:outlined="!isExactMatch(text, keyword)"
						rounded
						@click="proceed(keyword)"
					>
						{{ keyword }}
					</v-chip>
				</v-slide-item>
			</v-slide-group>
		</v-expand-transition>
	</div>
</template>

<script>
import { mindMapHelper } from "@/utils/helpers/mindmap";
import { isExactMatch } from "@/utils/helpers";

export default {
	name: "SuggestedKeywords",

	props: {
		text: {
			required: true,
		},
		current_questionnaire: {
			required: true,
		},
		question_order: {
			required: true,
		},
	},

	data() {
		return {
			isExactMatch,
			fav_keywords: [],
		};
	},

	computed: {
		keywords() {
			let arr = this.fav_keywords.map((item) => item.name);
			return [...new Set(arr)];
		},

		isChooseThemeRoute() {
			return this.$route.name === "ChooseThemeSummary";
		},

		isChooseTopicRoute() {
			return this.$route.name === "ChooseTopicSummary";
		},

		showSuggestions() {
			return (
				(this.current_questionnaire === this.$defines.QUESTIONNAIRE.q10 &&
					this.question_order === 1) ||
				(this.current_questionnaire === this.$defines.QUESTIONNAIRE.q20 &&
					this.question_order === 2) ||
				(this.current_questionnaire === this.$defines.QUESTIONNAIRE.q30 &&
					this.question_order === 1)
			);
		},
	},

	mounted() {
		this.getKeywords();
	},

	methods: {
		async getKeywords() {
			try {
				this.$loader.start();

				if (this.isChooseThemeRoute) {
					await this.getThemeKeywords();
				}
				if (this.isChooseTopicRoute) {
					await this.getMindmapKeywords();
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async getMindmapKeywords() {
			// Get wizard map
			let wizard_map_keywords = await mindMapHelper.getWizardMapFavKeywords();

			// Get standard map
			let standard_map_keywords = await mindMapHelper.getStandardMapFavKeywords();

			// Merge both keywords
			this.fav_keywords = [...wizard_map_keywords, ...standard_map_keywords];
		},

		async getThemeKeywords() {
			let res = await this.$store.dispatch("category/getAllFavorites");
			if (!res) return;

			res.forEach((item) => {
				this.fav_keywords.push({
					name: item.name,
				});
			});
		},

		async proceed(keyword) {
			try {
				// If already exist then do not proceed.
				if (isExactMatch(this.text, keyword)) return;

				// 'DEFINES[q20]' questionnaire does not need any restriction.
				if (
					this.text &&
					this.current_questionnaire !== this.$defines.QUESTIONNAIRE.q20
				) {
					throw new Error(
						this.$t("app.ntfy.err.only_one_option_allowed", {
							label: this.isChooseThemeRoute
								? this.$t("app.theme")
								: this.$t("app.topic"),
						}),
						{
							cause: "werCustom",
						},
					);
				}

				this.$emit("push-to-answer", keyword);
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>
