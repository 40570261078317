<template>
	<v-col cols="12" v-if="questionnaire">
		<!-- Question -->
		<div :class="question_styles">
			{{ question }}
		</div>
		<!-- Answer -->
		<div :class="answer_styles">
			{{ getNameOfAnswer(answer) }}
		</div>
	</v-col>
</template>

<script>
import { formAnswersMixin } from "@/mixins/questionnaire/formAnswersMixin.js";

export default {
	name: "RadioButtonAnswer",

	mixins: [formAnswersMixin],
};
</script>
