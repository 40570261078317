<template>
	<div class="ps-5">
		<template v-if="questionnaire_data && questionnaire_data.length">
			<v-row v-for="(questionnaire, index) in questionnaire_data" :key="index">
				<v-col cols="12">
					<!-- QUESTION -->
					<div :class="question_classes">
						{{ index + 1 }}.&ensp; {{ questionnaire.question.name }}
					</div>
					<!-- ANSWERS -->
					<div>
						<!-- Text answer -->
						<div
							v-if="
								isArrayHavingItem(
									['open', 'large_open'],
									questionnaire.question.type,
								)
							"
							:class="answer_classes"
						>
							{{ questionnaire.formated_answer }}
						</div>
						<!-- Choice answers -->
						<div
							v-if="
								isArrayHavingItem(
									['rank', 'multiple_choice'],
									questionnaire.question.type,
								)
							"
							:class="answer_classes"
						>
							{{ prepareChoiceAnswer(questionnaire) }}
						</div>
						<!-- Media answers -->
						<div v-if="questionnaire.question.type === 'self_document'">
							<!-- Image -->
							<AppQuestionnaireImage
								v-if="questionnaire.attachment"
								:source_url="
									`${$environments.base_api_url}${questionnaire.attachment}`
								"
							></AppQuestionnaireImage>
							<!-- Video -->
							<AppQuestionnaireVideo
								v-if="questionnaire.aws"
								:recording_url="questionnaire.aws"
							></AppQuestionnaireVideo>
						</div>
					</div>
				</v-col>
			</v-row>
		</template>

		<NoData
			v-else
			heading="app.questionnaire_incomplete_yet"
			:show_action="false"
		></NoData>
	</div>
</template>

<script>
import { findFromArray, isArrayHavingItem, filterArray } from "@/utils/helpers";
import AppQuestionnaireVideo from "@/components/ui/AppQuestionnaireVideo.vue";
import AppQuestionnaireImage from "@/components/ui/AppQuestionnaireImage.vue";
import NoData from "@/components/shared/NoData.vue";

export default {
	name: "AppQuestionnaire",

	props: {
		prop_questionnaire: {
			required: true,
		},
	},

	data() {
		return {
			isArrayHavingItem,
			questionnaire_data: null,
			question_classes: ["subtitle-1", "ms-2"],
			answer_classes: [
				"mt-2",
				"ms-8",
				"body-1 font-weight-thin",
				"white-space-pre-line-wr",
				"greyDarken2--text",
			],
		};
	},

	components: {
		AppQuestionnaireVideo,
		AppQuestionnaireImage,
		NoData,
	},

	mounted() {
		this.checkIfAllAnswersEmpty();
	},

	methods: {
		findAnswerById(arr, id) {
			let result = findFromArray(arr, "id", id);
			return result !== undefined ? result.name : null;
		},

		checkIfAllAnswersEmpty() {
			let total_answers = this.prop_questionnaire;

			// find the answers which are null
			let unanswered = filterArray(total_answers, "answer", null, true);

			// in all answers are empty that means questionnaire is of no use
			this.questionnaire_data =
				unanswered.length == total_answers.length
					? null
					: this.prop_questionnaire;
		},

		prepareChoiceAnswer(questionnaire) {
			try {
				// If question is of type multip_choice, then process an array.
				if (typeof questionnaire.formated_answer === "object") {
					let answer = [];
					questionnaire.formated_answer.forEach((item) => {
						let result = this.findAnswerById(
							questionnaire.question.options,
							item,
						);
						if (result) {
							answer.push(result);
						}
					});
					return answer.join(", ");
				}

				// If question is single choice, then find the single answer
				return this.findAnswerById(
					questionnaire.question.options,
					questionnaire.formated_answer,
				);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
