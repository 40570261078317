var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.input)?_c('v-card',{attrs:{"flat":"","height":"100%","id":"wizard-map-wr"}},[_c('AppActionsTheme',{attrs:{"type":"primary","actions":_vm.primaryActions},on:{"handle-function-call":_vm.handleFunctionCall}}),(_vm.isOwner && !_vm.isProcessMap)?_c('AppActionsTheme',{attrs:{"type":"required","actions":_vm.requiredActions},on:{"handle-function-call":_vm.handleFunctionCall}}):_vm._e(),_c('ZoomController',{attrs:{"el":"#downloadable-container-wr"}}),(_vm.input.data.length)?_c('v-card',{attrs:{"outlined":"","id":"downloadable-container-wr","height":"100%"}},_vm._l((_vm.input.data),function(item,index){return _c('vue-resizable',{directives:[{name:"debounce",rawName:"v-debounce:1s",value:(_vm.manageTyping(item.id)),expression:"manageTyping(item.id)",arg:"1s"}],key:index,ref:"resizableComponent",refInFor:true,staticClass:"resizable resizable-wr",attrs:{"fitParent":true,"width":item.width,"height":item.height,"left":item.left,"top":item.top,"id":("resize-" + (item.id)),"dragSelector":_vm.isProcessMap ? '.empty' : '.drag-container-wr',"active":_vm.activeDimensions},on:{"resize:move":function($event){return _vm.eHandler(item.id, $event)},"resize:end":function($event){return _vm.eHandler(item.id, $event)},"drag:end":function($event){return _vm.eHandler(item.id, $event)}}},[_c('div',{staticClass:"drag-container-wr"},[_c('v-menu',{attrs:{"offset-y":"","attach":_vm.is_full_screen ? ("#resize-" + (item.id)) : false,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var _obj;

var on = ref.on;
var attrs = ref.attrs;return [_c('div',{attrs:{"id":item.id}},[_c('v-textarea',_vm._g(_vm._b({class:[
									( _obj = {
										'elevation-0': true,
										'font-weight-bold': item.bold,
										'font-italic': item.italic
									}, _obj[item.font] = true, _obj ) ],attrs:{"solo":"","flat":"","tile":"","no-resize":"","hide-details":"","height":item.height,"background-color":item.background,"color":item.color,"readonly":_vm.isProcessMap,"placeholder":_vm.$t('app.placeholder.type_text_here', {
										index: index,
									})},model:{value:(item.text),callback:function ($$v) {_vm.$set(item, "text", $$v)},expression:"item.text"}},'v-textarea',attrs,false),on))],1)]}}],null,true)},[(!_vm.isProcessMap)?_c('Editor',{attrs:{"item":item,"is_full_screen":_vm.is_full_screen},on:{"delete-node":function($event){return _vm.deleteNode(item.id)},"update-style":function($event){return _vm.updateStyle(item, $event)},"prepare-attachment-process":function($event){return _vm.prepareAttachmentProcess(item)}}}):_vm._e()],1)],1)])}),1):_vm._e(),_c('AttachmentDialog',{attrs:{"dialog":_vm.attachment_dialog,"node":_vm.selected_node},on:{"close":function($event){_vm.attachment_dialog = false},"confirm":_vm.setAttachment},model:{value:(_vm.attachment_dialog),callback:function ($$v) {_vm.attachment_dialog=$$v},expression:"attachment_dialog"}}),_c('TheConfirmation',{attrs:{"dialog":_vm.delete_dialog},on:{"cancel":function($event){_vm.delete_dialog = false},"confirm":function($event){return _vm.proceedToDelete()}},model:{value:(_vm.delete_dialog),callback:function ($$v) {_vm.delete_dialog=$$v},expression:"delete_dialog"}}),_c('ShareDialog',{attrs:{"dialog":_vm.share_dialog,"uuid":_vm.input.uuid},on:{"close":function($event){_vm.share_dialog = false},"confirm":function($event){_vm.share_dialog = false}},model:{value:(_vm.share_dialog),callback:function ($$v) {_vm.share_dialog=$$v},expression:"share_dialog"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }