<template>
	<v-card flat width="100%" align="center">
		<v-card-text class="title">
			{{
				$t("app.see_full_research_paper", {
					student_name: fullName(current_student.user),
				})
			}}
		</v-card-text>
		<v-card-text>
			<AppButton
				label="app.see_research_paper"
				color="info"
				:icon="$vuetify.rtl ? 'left_chevron' : 'right_chevron'"
				@click="redirectTo()"
			></AppButton>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
	name: "ResearchPaperAssignment",

	computed: {
		...mapState({
			current_student: (state) => state.student.current_student,
		}),

		...mapGetters({
			fullName: "user/fullName",
			isAdmin: "user/isAdmin",
		}),
	},

	methods: {
		redirectTo() {
			try {
				this.$router.push({
					name: this.isAdmin
						? "AdminStudentResearchPaper"
						: "StudentResearchPaper",
					params: {
						id: this.current_student.id,
					},
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>

<style></style>
