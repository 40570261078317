<template>
	<div v-if="list && list.answers && list.answers.length">
		<v-btn
			fab
			color="error"
			depressed
			width="45"
			height="45"
			class="ms-6"
			v-tooltip="{
				content: $t('app.edit'),
				placement: $vuetify.rtl ? 'left' : 'right',
				targetClasses: ['it-has-a-tooltip'],
				offset: 10,
				classes: ['edit'],
			}"
			@click="makeQuestionnaireEditable()"
		>
			<v-icon>
				$vuetify.icons.values.edit
			</v-icon>
		</v-btn>

		<!--
        The loop will check questionnaire's question type and create
        components accordingly.
       -->
		<v-row v-for="(item, index) in list.answers" :key="index" class="mt-3">
			<component
				v-if="item"
				:is="decideComponent(item.question.type)"
				:questionnaire="item"
			>
			</component>
		</v-row>
	</div>
</template>

<script>
import TextField from "./TextField";
import TextArea from "./TextArea";
import CheckBox from "./CheckBox";
import RadioButton from "./RadioButton";
import Document from "./Document";
import { mapState } from "vuex";

export default {
	name: "FormGenerator",

	computed: {
		...mapState({
			list: (state) => state.questionnaire.list,
		}),
	},

	components: {
		TextField,
		TextArea,
		CheckBox,
		RadioButton,
		Document,
	},

	methods: {
		decideComponent(type) {
			/**
			 * From backend the input field types are having different names.
			 * Ex:- "radio_buttons" have name "rank", text_fields have name "open" etc.
			 * So, below code will decide the component name according code.
			 */
			let eligible_component = null;
			switch (type) {
				case "open":
					eligible_component = "TextField";
					break;
				case "large_open":
					eligible_component = "TextArea";
					break;
				case "rank":
				case "single_choice":
					eligible_component = "RadioButton";
					break;
				case "self_document":
					eligible_component = "Document";
					break;
				case "multiple_choice":
					eligible_component = "CheckBox";
					break;
			}
			return eligible_component;
		},

		async makeQuestionnaireEditable() {
			try {
				this.$loader.start();

				await this.$store.dispatch("questionnaire/submit", {
					questionnaire_id: this.list.id,
					status: this.$defines.QUESTIONNAIRE_STATUS_DRAFT_STRING,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
