<template>
	<v-col cols="12" v-if="questionnaire">
		<v-form ref="textFieldForm" lazy-validation>
			<!-- Question -->
			<div :class="question_styles">
				{{ question }}
				<!-- Information Icon -->
				<InfoIcon :message="questionnaire.question.description"></InfoIcon>
			</div>

			<!-- Show the keywords as suggestion -->
			<SuggestedKeywords
				:text="questionnaire.formated_answer"
				:current_questionnaire="current_questionnaire"
				:question_order="questionnaire.question.order"
				@push-to-answer="pushKeywordToAnswer($event, 'textFieldForm')"
			></SuggestedKeywords>

			<!-- Input field -->
			<div class="mt-2">
				<v-text-field
					outlined
					dense
					color="greenAccent2"
					background-color="white"
					:placeholder="$t('app.placeholder.type_answer')"
					v-model="questionnaire.formated_answer"
					:rules="
						questionnaire.question.required === 'true' ? [rules.required] : []
					"
					@blur="submitAnswerIfModified('textFieldForm')"
				>
					<span slot="append">
						<SpeechToText
							:text="questionnaire.formated_answer"
							@speechend="onSpeechEnd($event.text, 'textFieldForm')"
						></SpeechToText>
					</span>
					<span slot="append" class="ms-3">
						<TextToSpeech :text="questionnaire.formated_answer"></TextToSpeech>
					</span>
					<span slot="append" class="ms-3">
						<Translator :text="questionnaire.formated_answer"></Translator>
					</span>
				</v-text-field>
			</div>
		</v-form>
	</v-col>
</template>

<script>
import { formActionsMixin } from "@/mixins/questionnaire/formActionsMixin.js";
import SpeechToText from "@/components/plugins/SpeechToText";
import SuggestedKeywords from "@/components/research/modules/_common/SuggestedKeywords.vue";
import TextToSpeech from "@/components/plugins/TextToSpeech.vue";
import Translator from "@/components/plugins/Translator.vue";

export default {
	name: "TextField",

	mixins: [formActionsMixin],

	components: {
		SpeechToText,
		SuggestedKeywords,
		TextToSpeech,
		Translator,
	},

	computed: {
		showSuggestion() {
			return (
				(this.current_questionnaire === this.$defines.QUESTIONNAIRE.q30 &&
					this.questionnaire.question.order === 1) ||
				(this.current_questionnaire === this.$defines.QUESTIONNAIRE.q10 &&
					this.questionnaire.question.order === 1)
			);
		},
	},
};
</script>
