<template>
	<v-col cols="12" v-if="questionnaire">
		<!-- Question -->
		<div :class="question_styles">
			{{ question }}
		</div>

		<!-- Answer -->
		<AppQuestionnaireImage
			v-if="questionnaire.attachment"
			:source_url="`${$environments.base_api_url}${questionnaire.attachment}`"
		></AppQuestionnaireImage>

		<AppQuestionnaireVideo
			v-if="questionnaire.aws"
			:recording_url="questionnaire.aws"
		></AppQuestionnaireVideo>
	</v-col>
</template>

<script>
import { formAnswersMixin } from "@/mixins/questionnaire/formAnswersMixin.js";
import AppQuestionnaireVideo from "@/components/ui/AppQuestionnaireVideo.vue";
import AppQuestionnaireImage from "@/components/ui/AppQuestionnaireImage.vue";

export default {
	name: "DocumentAnswer",

	mixins: [formAnswersMixin],

	components: {
		AppQuestionnaireVideo,
		AppQuestionnaireImage,
	},
};
</script>
